import { ApiConstants } from '../constants';
import apiUtil from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';

async function registerByEmailAndPassword({
  email,
  password,
  fullName,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.REGISTER_BY_EMAIL_PASSWORD(), {
      email,
      password,
      fullName,
    }, {});

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loginByEmailAndPassword({
  email,
  password,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.LOGIN_BY_EMAIL_PASSWORD(), {
      email,
      password,
    }, {});

    const { user, token } = result.data;
    return { user, token };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function emailRegistrationVerification({
  tokenKey,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.EMAIL_REGISTRATION_LINK_VERIFICATION(), {
      tokenKey,
    }, {});

    const { user, token } = result.data;

    return { user, token };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function requestResetPassword({
  email,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.REQUEST_RESET_PASSWORD(), {
      email,
    }, {});

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function resetThenCreateNewPassword({
  tokenKey,
  password,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.RESET_THEN_CREATE_NEW_PASSWORD(), {
      tokenKey,
      password,
    }, {});

    const { user, token } = result.data;

    return { user, token };
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function changePassword({
  email,
  passwordBefore,
  password,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.CHANGE_PASSWORD(), {
      email,
      passwordBefore,
      password,
    }, {});

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function requestChangeEmail({
  email,
  emailBefore,
}) {
  try {
    const result = await apiUtil.post(ApiConstants.URL_V1.REQUEST_CHANGE_EMAIL(), {
      email,
      emailBefore,
    }, {});

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function addNewPassword({
  email,
  password,
}) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.ADD_NEW_PASSWORD_FOR_USER_WITHOUT_PASSWORD(), {
        email,
        password,
      }, {},
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function checkUserHasPassword({
  userId,
}) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.CHECK_USER_HAS_PASSWORD({ userId }), {});

    const { hasPassword } = result.data;

    return hasPassword;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  registerByEmailAndPassword,
  emailRegistrationVerification,
  loginByEmailAndPassword,
  requestResetPassword,
  resetThenCreateNewPassword,
  checkUserHasPassword,
  changePassword,
  requestChangeEmail,
  addNewPassword,
};
