import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import Colors from '../../../../themes/colors';

const styles = {
  default: {
    fontSize: '12px',
    fontFamily: 'Nunito',
    color: Colors.fontColor,
    margin: 0,
  },
  underline: {
    textDecoration: 'underline',
  },
};

const fontFamilyStyles = {
  nunito: { fontFamily: 'Nunito' },
  openSans: { fontFamily: 'Open Sans' },
};

const modeStyles = {
  14: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  13: {
    fontSize: '13px',
    lineHeight: '17px',
  },
  12: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  11: {
    fontSize: '11px',
    lineHeight: '15px',
  },
};

const decorationStyles = {
  bold: { fontWeight: 'bold' },
  semibold: { fontWeight: 500 },
  regular: { fontWeight: 'normal' },
};

const fontStyleStyles = {
  normal: { fontStyles: 'normal' },
  italic: { fontStyle: 'italic' },
};

const positionStyles = {
  left: { textAlign: 'left' },
  center: { textAlign: 'center' },
  right: { textAlign: 'right' },
};

const DisplayTextBody = (props) => {
  const {
    className,
    children,
    style,
    mode,
    decoration,
    position,
    fontFamily,
    color,
    underline,
    fontStyle,
  } = props;

  const colorStyle = color && { color };
  const underlineStyle = underline && styles.underline;

  const isTablet = useMediaQuery('(max-width:1024px)');
  const isMobile = useMediaQuery('(max-width:720px)');

  const tabletModeStyles = isTablet && modeStyles[12];
  const mobileModeStyles = isMobile && modeStyles[12];

  return (
    <p
      className={className}
      style={
        {
          ...styles.default,
          ...modeStyles[mode],
          ...decorationStyles[decoration],
          ...positionStyles[position],
          ...fontFamilyStyles[fontFamily],
          ...colorStyle,
          ...style,
          ...underlineStyle,
          ...fontStyleStyles[fontStyle],
          ...tabletModeStyles,
          ...mobileModeStyles,
        }
    }
    >
      {children}
    </p>
  );
};

DisplayTextBody.propTypes = {
  decoration: PropTypes.oneOf(['bold', 'semibold', 'regular']),
  position: PropTypes.oneOf(['left', 'center', 'right']),
  fontFamily: PropTypes.oneOf(['nunito', 'openSans']),
  fontStyle: PropTypes.oneOf(['normal', 'italic']),
  mode: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  color: PropTypes.string,
  underline: PropTypes.bool,
};

DisplayTextBody.defaultProps = {
  decoration: 'regular',
  position: 'left',
  fontFamily: 'nunito',
  mode: '12',
  className: '',
  style: {},
  color: '',
  underline: false,
  fontStyle: 'normal',
};

export default DisplayTextBody;
