/* eslint-disable consistent-return */
import {
  useCallback, useState, useEffect, useRef,
} from 'react';
import { cloneDeep, isArray, lowerCase } from 'lodash';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { dateDiffInDays } from '../utilities/dateUtil';

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

const useSearch = ({
  originalList, keyObjectProperty, initialKeyword, debounceDelay = 400,
}) => {
  const [keyword, setKeyword] = useState(initialKeyword || '');
  const [keywordArray, setKeywordArray] = useState([]);
  const [result, setResult] = useState(originalList);
  const [isTyping, setIsTyping] = useState(false);
  const debouncedValue = useDebounce(keyword, debounceDelay);

  const onChange = useCallback((value) => {
    setKeyword(value);
  }, []);

  const getNestedObject = useCallback((nestedObj, pathArr) => pathArr.reduce((obj, key) => ((obj && obj[key] !== 'undefined') ? obj[key] : undefined), nestedObj), []);

  const getSearchResult = useCallback((list, keywordString) => {
    if (!isArray(list)) return [];
    const newList = list.filter((item) => {
      let searchPart = item.fullName || item.name || item.title || item.content;
      if (keyObjectProperty && keyObjectProperty?.length > 0) {
        searchPart = getNestedObject(item, keyObjectProperty);
      }
      const lowName = lowerCase(searchPart);
      const lowKeywords = lowerCase(keywordString).split(' ');
      setKeywordArray(lowKeywords);

      return lowKeywords.every((lowKeyword) => lowName.includes(lowKeyword));
    });
    return newList;
  }, []);

  useEffect(() => {
    const newList = getSearchResult(originalList, debouncedValue);
    setResult([...newList]);
  }, [debouncedValue, JSON.stringify(originalList)]);

  const handleStatusTyping = useCallback((value) => {
    if (value === isTyping) return;
    setIsTyping(value);
  }, [isTyping]);

  useEffect(() => {
    if (keyword !== debouncedValue) {
      if (!isTyping) handleStatusTyping(true);
    } else if (isTyping) handleStatusTyping(false);
  }, [keyword, debouncedValue]);

  const resetSearch = useCallback(() => {
    setKeyword('');
  }, []);

  return {
    searchResult: result,
    setSearchResult: setResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    debouncedKeyword: debouncedValue,
    resetSearch,
  };
};

const useSelectItemsOnList = ({ list = [], initialSelectedItems, invertResetCondition }) => {
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems || []);
  const [isAllItemsSelected, setIsAllItemsSelected] = useState(false);
  const [isNoneItemsSelected, setIsNoneItemsSelected] = useState(false);

  const getAndCopyInitialSelectedItems = (initialItems) => {
    let newSelectedItems = [];
    newSelectedItems = initialItems.map((item) => item?._id || item);
    return newSelectedItems;
  };

  const checkAndSetAllItemsSelected = (items) => {
    const isAllBeenSelected = items.length === list.length;
    if (isAllBeenSelected) {
      setIsAllItemsSelected(true);
    } else {
      setIsAllItemsSelected(false);
    }
  };

  const checkAndSetNoneItemsSelected = (items) => {
    const isNoneBeenSelected = items.length < 1;
    if (isNoneBeenSelected) {
      setIsNoneItemsSelected(true);
    } else {
      setIsNoneItemsSelected(false);
    }
  };

  const handleSelectAll = () => {
    let newSelectedItems = [];
    if (isAllItemsSelected) {
      newSelectedItems = [];
    } else {
      newSelectedItems = list.map((item) => item?._id || item);
    }
    setSelectedItems(newSelectedItems);
    checkAndSetNoneItemsSelected(newSelectedItems);
    checkAndSetAllItemsSelected(newSelectedItems);
  };

  const handleReset = () => {
    let newSelectedItems = [];
    if (invertResetCondition) {
      newSelectedItems = list.map((item) => item?._id || item);
    }
    setSelectedItems(newSelectedItems);
    checkAndSetNoneItemsSelected(newSelectedItems);
    checkAndSetAllItemsSelected(newSelectedItems);
  };

  const handleSelectItem = (itemId) => {
    let newSelectedItems = [];

    newSelectedItems = getAndCopyInitialSelectedItems(selectedItems);

    if (!newSelectedItems.includes(itemId)) {
      newSelectedItems.push(itemId);
      setSelectedItems([...newSelectedItems]);
    } else {
      newSelectedItems = newSelectedItems.filter((id) => id !== itemId);
      setSelectedItems([...newSelectedItems]);
    }

    checkAndSetNoneItemsSelected(newSelectedItems);
    checkAndSetAllItemsSelected(newSelectedItems);
  };

  useEffect(() => {
    const newSelectedItems = getAndCopyInitialSelectedItems(initialSelectedItems);
    setSelectedItems(newSelectedItems);
    checkAndSetNoneItemsSelected(newSelectedItems);
    checkAndSetAllItemsSelected(newSelectedItems);
  }, [initialSelectedItems]);

  return [
    selectedItems,
    handleSelectItem,
    isAllItemsSelected,
    handleSelectAll,
    isNoneItemsSelected,
    handleReset,
  ];
};

function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);
      // Add event listener
      element.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element], // Re-run if eventName or element changes
  );
}

const useFocusAndBlur = () => {
  const htmlElRef = useRef(null);

  const setFocus = useCallback(() => {
    if (htmlElRef.current) {
      htmlElRef.current.focus();
    }
  }, [htmlElRef]);

  const setBlur = useCallback(() => {
    if (htmlElRef.current) {
      htmlElRef.current.blur();
    }
  }, [htmlElRef]);

  return [htmlElRef, setFocus, setBlur];
};

const useParamsCompanyIdTeamId = ({ pathname }) => {
  const [emptyString, companyUrl, foundCompanyId, teamUrl, foundTeamId] = pathname.split('/');
  let companyId;
  let teamId;

  if (companyUrl === 'companies' && foundCompanyId) {
    companyId = foundCompanyId;
  }

  if (teamUrl === 'teams' && foundTeamId) {
    teamId = foundTeamId;
  }

  return {
    companyId,
    teamId,
  };
};

const useDelayShowHideHandler = () => {
  const [show, setShow] = useState(false);
  const [isDelay, setIsDelay] = useState(false);

  const handleShow = useCallback((value) => {
    if (isDelay) return;
    setShow(value || true);
  }, [isDelay]);

  const handleHide = useCallback(() => {
    setShow(show !== true ? undefined : false);
    setIsDelay(true);
  }, []);

  useEffect(() => {
    if (!isDelay) return;
    setTimeout(() => {
      setIsDelay(false);
    }, 100);
  }, [isDelay]);

  return [
    show,
    handleShow,
    handleHide,
  ];
};

const useSelectFromUntilDate = (
  initialFromDate,
  initialUntilDate,
) => {
  const today = new Date();
  const [initFromDate, setInitFromDate] = useState(initialFromDate || today);
  const [initUntilDate, setInitUntilDate] = useState(initialUntilDate || today);
  const [fromDate, setFromDate] = useState(initialFromDate || today);
  const [untilDate, setUntilDate] = useState(initialUntilDate || today);

  const { enqueueSnackbar } = useSnackbar();

  const handleDateChangeFrom = useCallback((date) => {
    if (date >= untilDate) {
      const endDate = new Date(date);
      endDate.setHours(endDate.getHours() + 1);
      setUntilDate(endDate);

      if (date >= untilDate) {
        setUntilDate(endDate);
      }
    }
    setFromDate(date);
  }, [untilDate]);

  const handleDateChangeUntil = (date, disableFromDateCheck) => {
    if (!disableFromDateCheck) {
      if (dateDiffInDays(fromDate, date) < 0) {
        enqueueSnackbar('Cannot choose end date time before start date time', {
          variant: 'error',
        });
        return;
      }
    }
    setUntilDate(date);
  };

  const handleDateChangeInitFrom = (date) => {
    setInitFromDate(date);
  };

  const handleDateChangeInitUntil = (date) => {
    setInitUntilDate(date);
  };

  useEffect(() => {
    handleDateChangeInitFrom(initialFromDate);
    handleDateChangeInitUntil(initialUntilDate);
  }, [initialFromDate, initialUntilDate]);

  const handleReset = () => {
    handleDateChangeInitFrom(initialFromDate);
    handleDateChangeInitUntil(initialUntilDate);
  };

  return [
    initFromDate,
    fromDate,
    handleDateChangeFrom,
    handleDateChangeInitFrom,
    initUntilDate,
    untilDate,
    handleDateChangeUntil,
    handleDateChangeInitUntil,
    handleReset,
  ];
};

const useCheckPrevLocationTheSame = () => {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(location);
  const [isPrevLocTheSame, setIsPrevLocTheSame] = useState(false);

  useEffect(() => {
    if (previousLocation) {
      if (location.pathname === previousLocation.pathname) {
        // Location exactly the same
        setIsPrevLocTheSame(true);
        setPreviousLocation(location);
        return;
      }
    }

    setIsPrevLocTheSame(false);
    setPreviousLocation(location);
  }, [location]);

  return [isPrevLocTheSame, previousLocation];
};

export {
  useDebounce,
  useLocalStorage,
  useSearch,
  useEventListener,
  useFocusAndBlur,
  useParamsCompanyIdTeamId,
  useDelayShowHideHandler,
  useSelectItemsOnList,
  useSelectFromUntilDate,
  useCheckPrevLocationTheSame,
};
