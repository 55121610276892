import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import styles from './WidgetSection.module.css';
import Color from '../../../themes/colors';
import { DisplayTextBody, DisplayTextHeadline } from '../../../components/UI_V2/Display/Text';
import WidgetRecentlyVisit from './WidgetRecentlyVisit/WidgetRecentlyVisit';
import WidgetMyTaskCardAll from './WidgetMyTaskCardAll/WidgetMyTaskCardAll';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { dateBeautifyFormatter, getGreeting } from '../../../utilities/dateUtil';

const itemWithSidebarOpenStyles = {
  false: `${styles.item} ${styles.itemSidebarOpen}`,
  true: styles.item,
};

const WidgetSection = () => {
  const [{ user, currentCompany, isSidebarOpen }] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const today = new Date();
  const todayString = dateBeautifyFormatter(today, true, 'DayMonthDateYear');
  const greeting = getGreeting(today);
  return (
    <div className={styles.container}>
      {!isMobile && (
      <div className={styles.welcomeText}>
        <DisplayTextBody decoration="bold" color={Color.gray3}>
          {todayString}
        </DisplayTextBody>
        <DisplayTextHeadline decoration="bold">
          {`${greeting}, ${user?.fullName}`}
        </DisplayTextHeadline>
      </div>
      )}
      <div className={styles.widget}>
        <div className={itemWithSidebarOpenStyles[isSidebarOpen]}>
          <WidgetMyTaskCardAll userId={user?._id} companyId={currentCompany?._id} />
        </div>
        <div className={itemWithSidebarOpenStyles[isSidebarOpen]}>
          <WidgetRecentlyVisit />
        </div>
      </div>
    </div>
  );
};

WidgetSection.propTypes = {};

WidgetSection.defaultProps = {};

export default memo(WidgetSection);
