import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LabelIcon from '@mui/icons-material/Label';
import EventIcon from '@mui/icons-material/Event';
import LockIcon from '@mui/icons-material/Lock';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { isEmpty } from 'lodash';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArchiveIcon from '@mui/icons-material/Archive';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useSnackbar } from 'notistack';
import { useInView } from 'react-intersection-observer';
import { GlobalContext, initialState } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import styles from './CardDetailContainer.module.css';
import Button from '../../components/UI/Button/Button';
import Members from './Members/Members';
import ManageMultipleMembersContainer from '../ManageMultipleMembersContainer/ManageMultipleMembersContainer';
import CardLabels from './CardLabels/CardLabels';
import ManageDueDateContainer from '../ManageDueDateContainer/ManageDueDateContainer';
import ManageMoveCard from './ManageMoveCard/ManageMoveCard';
import DueDate from './DueDate/DueDate';
import ManageDesc from './ManageDesc/ManageDesc';
import CommentsSectionContainer from '../CommentsSectionContainer/CommentsSectionContainer';
import ManageAttachments from './ManageAttachments/ManageAttachments';
import ArchiveButtonContainer from './ArchiveButtonContainer/ArchiveButtonContainer';
import ChangeNameForm from './ChangeNameForm/ChangeNameForm';
import Title from '../../components/Title/Title';
import AttachFileContainer from '../AttachFileContainer/AttachFileContainer';
import ImageMember from '../../components/UI/ImageMember/ImageMember';
import DateBox from '../../components/UI/DateBox/DateBox';
import { dateBeautifyFormatter } from '../../utilities/dateUtil';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import CheersContainer from '../CheersContainer/CheersContainer';
import { CardActions, CommentActions, SearchJumpToActions } from '../../actions';
import { useCardActivities, useCardCheerNCommentSocket, useCardV2Socket } from '../../hooks/CardHooks';
import { useBoardV2Socket } from '../../hooks/BoardHooks';
import apiUtil from '../../utilities/apiUtil';
import InfinityScroll from '../../components/UI/InfinityScroll/InfinityScroll';
import { useInfinityScrollHooks } from '../../hooks/InfinityScrollHooks';
import { ErrorException } from '../../utilities/handleError';
import { useDropZoneFile } from '../../hooks/DropFileHook';
import PrivateIcon from '../../components/UI/PrivateIcon/PrivateIcon';
import ChangeAccessCardContainer from './ChangeAccessCardContainer/ChangeAccessCardContainer';
import ManageLabelsContainer from '../ManageLabelsContainer/ManageLabelsContainer';
import { FeedbackSpinnerGeneral } from '../../components/UI_V2/Feedback/Spinner';
import Color from '../../themes/colors';
import { PageConstants, SeenConstants } from '../../constants';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { roleTypeRef, typeRef } from '../../constants/RoleConstants';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import GlobalActionButton from '../../components/GeneralSubNavBar/BottomNavBar/GlobalActionButton/GlobalActionButton';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import MyRoleUser from '../../components/Container/MyRoleUser/MyRoleUser';
import { checkIfUserAuthorizedToEditData, checkIfUserAuthorizedToModifyCard } from '../../actions/UserActions';
import SeenInfo from '../../components/Container/SeenModal/SeenInfo/SeenInfo';
import SeenModal from '../../components/Container/SeenModal/SeenModal';
import { useInitiateTeamBelowRole } from '../../hooks/TeamHooks';
import { serviceTypeRef } from '../../constants/ActivityConstants';
import ListActivitiesCard from './ListActivitiesCard/ListActivitiesCard';
import UserTyping from '../../components/Container/UserTyping/UserTyping';
import { useUserTypingCardSocket } from '../../hooks/UserTypingHooks';
import { useMarkReadNotificationOnPageOpen } from '../../hooks/NotificationHooks';
import { DisplayDividerMain } from '../../components/UI_V2/Display/Divider';

function CardDetailContainer() {
  const [{
    user, currentCard, currentBoard, currentCardList, currentTeam, currentBoardLists,
    previousCardComment, currentCompany, currentRoleUser, currentTeamBelowRole,
    allUserTypingCard,
  }, dispatch] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [openCreateCommentForm, setOpenCreateCommentForm] = useState(false);

  const [
    showManageMembers,
    handleShowManageMembers,
    handleHideManageMembers,
  ] = useDelayShowHideHandler();

  const [
    showManageLabels,
    handleShowManageLabels,
    handleHideManageLabels,
  ] = useDelayShowHideHandler();
  const [
    showManageDueDate,
    handleShowManageDueDate,
    handleHideManageDueDate,
  ] = useDelayShowHideHandler();
  const [
    showManageMoveCard,
    handleShowManageMoveCard,
    handleHideManageMoveCard,
  ] = useDelayShowHideHandler();
  const [
    showManageMoveCardFromTitle,
    handleShowManageMoveCardFromTitle,
    handleHideManageMoveCardFromTitle,
  ] = useDelayShowHideHandler();
  const [
    showChangeCardName,
    handleShowChangeCardName,
    handleHideChangeCardName,
  ] = useDelayShowHideHandler();

  const [
    showSeenModal,
    handleShowSeenModal,
    handleHideSeenModal,
  ] = useDelayShowHideHandler();
  const [
    showModalRoleUser,
    handleShowModalRoleUser,
    handleHideModalRoleUser,
  ] = useDelayShowHideHandler();

  const [
    showActivities,
    handleShowActivities,
    handleHideActivities,
  ] = useDelayShowHideHandler();
  const [loadings, setLoadings] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, cardId } = params;

  useInitiateRoleUser({
    payload: {
      teamId,
      featureType: typeRef.card,
      featureId: cardId,
    },
  });

  const socketUserTyping = useUserTypingCardSocket({
    companyId,
    cardId,
    teamId,
    userId: user?._id,
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  // // initiate members below role
  // useInitiateTeamBelowRole({});

  const isUserCreator = currentCard?.creator?._id === user?._id;

  // for open create comment
  const handleOpenCreateCommentForm = useCallback((value) => {
    setOpenCreateCommentForm(value);
  }, []);

  // for activities
  const {
    listActivities,
    handleLoadMoreListActivities,
    checkIfListNeedToLoadActivities,
    setCheckLoadMoreActivities,
    isListsLengthAtTheLimitOrAboveActivities,
    loadingsActivities,
  } = useCardActivities({ showActivities });

  const { socket } = useBoardV2Socket({ boardId: currentBoard?._id, userId: user?._id }, dispatch);
  useCardV2Socket({ cardId, userId: user?._id }, dispatch);
  useCardCheerNCommentSocket({ cardId, userId: user?._id }, dispatch);

  const [files, setFiles] = useState();
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length < 1) {
      setFiles(fileRejections);
    } else {
      setFiles(acceptedFiles);
    }
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    isDragActive,
  } = useDropZoneFile({
    onDrop,
  });

  const handleLoadingUpload = (value) => {
    setLoadingUpload(value);
  };
  const getMoreLists = async () => {
    try {
      const result = await CardActions.loadMoreCardComment({
        cardId,
        currentCard,
        companyId,
        teamId,
      }, dispatch);

      return result;
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }

      throw new ErrorException(error);
    }
  };

  const resetPreviousLists = useCallback(() => {
    CardActions.setPreviousCardComment({
      previousCardComment: initialState.previousCardComment,
    }, dispatch);
    CardActions.setCurrentCard({
      currentCard: initialState.currentCard,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentCard,
    previousLists: previousCardComment,
    resetPreviousLists,
    getMoreLists,
  });

  // need using observerintersection
  // for infinite scroll comment inside modal dialog
  const { ref, inView, entry } = useInView();
  useEffect(() => {
    if (inView) {
      if (showActivities) {
        if (checkIfListNeedToLoadActivities()) {
          handleLoadMoreListActivities();
        }
      }

      if (!showActivities) {
        if (checkIfListNeedToLoad()) {
          handleLoadMoreLists();
        }
      }
    }
  }, [inView]);

  useEffect(() => {
    if (files === undefined) {
      return;
    }

    const postApiCardAttachment = async () => {
      const data = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        data.append('file', files[i]);
      }
      handleLoadingUpload(true);
      try {
        const result = await apiUtil.post(`/api/v1/cards/${cardId}/attachments`, data, {
          params: {
            companyId,
            teamId,
          },
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
      } catch (err) {
        const getErrorMessageDropzoneFile = files?.[0]?.errors?.[0];
        const messageError = getErrorMessageDropzoneFile === undefined
          ? err : getErrorMessageDropzoneFile;
        const status = handleStatusMsg(messageError, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        handleLoadingUpload(false);
      }
    };
    postApiCardAttachment();
  }, [files]);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }
    const startLoadings = handleLoadings('cardPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchCardApi = async () => {
      try {
        // if (!currentCard || currentCard?._id !== cardId) {
        //   const result = await CardActions.initiateCardV2(
        //     {
        //       cardId,
        //       currentBoardId: currentBoard?._id,
        //       companyId,
        //       teamId,
        //       currentBoard,
        //       currentBoardLists,
        //     },
        //     dispatch,
        //   );
        //   if (isListsLengthAtTheLimitOrAbove(result?.data?.card?.comments)) {
        //     setCheckLoadMore(true);
        //   }
        // }
        const result = await CardActions.initiateCardV2(
          {
            cardId,
            currentCard,
            currentBoardId: currentBoard?._id,
            companyId,
            teamId,
            currentBoard,
            currentBoardLists,
            currentTeam,
          },
          dispatch,
        );
        if (isListsLengthAtTheLimitOrAbove(result?.data?.card?.comments)) {
          setCheckLoadMore(true);
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('cardPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchCardApi();
  }, []);

  const handleCheckToday = (card) => {
    const dateString = dateBeautifyFormatter(card.createdAt);

    if (dateString === 'Hari Ini') {
      return true;
    }
    return false;
  };

  const handleLoadingArchive = (status) => {
    if (status) {
      const startLoadings = handleLoadings('archiveCard', [...loadings], 'start');
      setLoadings([...startLoadings]);
    } else {
      const endLoadings = handleLoadings('archiveCard', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleLoadingChangeAccess = (status) => {
    if (status) {
      const startLoadings = handleLoadings('changeAccessCard', [...loadings], 'start');
      setLoadings([...startLoadings]);
    } else {
      const endLoadings = handleLoadings('changeAccessCard', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const pageType = PageConstants.pageType.cards;
  const breadcrumb = generateRoutesAndTitle(
    {
      type: pageType,
      data: {
        currentTeam, companyId, currentCard, currentBoard,
      },
    },
  );
  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: cardId,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title={currentCard.name === undefined ? 'Tugas' : currentCard.name} />
      <PageLoading wait="cardPage" loadings={loadings}>
        <div className={styles.CardDetailContainer__modalHeader}>

          <div className={styles.titleSection}>
            {currentCard?.archived?.status
            && (
              <>
                <div className={styles.archivedSection}>
                  <ArchiveIcon />
                  <h1>Tugas ini udah terarsip</h1>
                </div>
              </>
            )}
            {showChangeCardName
              ? <ChangeNameForm card={currentCard} cancel={handleHideChangeCardName} />
              : (
                <div>
                  <h1 onClick={checkIfUserAuthorizedToEditData(currentCard, user,
                    currentRoleUser, currentTeamBelowRole)
                    ? () => handleShowChangeCardName()
                    : () => null}
                  >
                    <PrivateIcon data={currentCard} size="small" />
                    {' '}
                    {currentCard.name}
                  </h1>
                </div>
              )}
            <div className={styles.listAndTeamSection}>
              <p>
                di dalam list
                {' '}
                <a
                  className={styles.listAndTeamSection__listName}
                  onClick={checkIfUserAuthorizedToModifyCard(currentCard, user, currentRoleUser)
                    ? () => handleShowManageMoveCardFromTitle()
                    : () => null}
                >
                  {currentCardList.name}
                </a>
                {' '}
                di
                {' '}
                <a>{currentTeam.name}</a>
              </p>
              {showManageMoveCardFromTitle ? (
                <ManageMoveCard
                  left
                  cancel={handleHideManageMoveCardFromTitle}
                  card={currentCard}
                  list={currentCardList}
                  socket={socket}
                />
              ) : null}
            </div>
            <div className={styles.createdSection}>

              <div className={styles.createdSection__photoNameDate} ref={ref}>
                {currentCard.creator
                  ? <ImageMember photoUrl={currentCard.creator?.photoUrl} />
                  : null}
                {currentCard.creator
                  ? (
                    <div className={styles.createdSection__photoNameDate__creatorAndDate}>
                      <p>{currentCard.creator?.fullName}</p>
                      {handleCheckToday(currentCard)
                        ? <DateBox date={currentCard.createdAt} relativeTime />
                        : <DateBox date={currentCard.createdAt} />}
                    </div>
                  )
                  : (
                    <div className={styles.createdSection__photoNameDate__notFound}>
                      <p>Creator card not found. This card created at : </p>
                      {handleCheckToday(currentCard)
                        ? <DateBox date={currentCard.createdAt} relativeTime />
                        : <DateBox date={currentCard.createdAt} />}
                    </div>
                  )}

              </div>
              <div className={styles.manageRole}>
                <GlobalActionButton
                  handleClick={handleShowModalRoleUser}
                  text="Akses Saya"
                  icon={<VerifiedUserIcon />}
                />
              </div>
            </div>
          </div>

        </div>
        <DisplayDividerMain borderColor={Color.gray6} />
        <div id="cardModal" className={styles.CardDetailContainer__modalBody}>
          <div className={styles.CardDetailContainer__mainColumn}>

            <div className={styles.CardDetailContainer__smallSectionContainer}>
              <Members card={currentCard} />
            </div>
            <div className={styles.CardDetailContainer__smallSectionContainer}>
              <CardLabels card={currentCard} />
            </div>
            <div className={styles.CardDetailContainer__smallSectionContainer}>
              <DueDate card={currentCard} />
            </div>
            <div className={styles.CardDetailContainer__mediumSectionContainer}>
              <ManageDesc card={currentCard} />
            </div>
            <div className={styles.container} {...getRootProps()}>
              {/* <div className={styles.container} > */}
              <div className={styles.box}>
                <div className={styles.CardDetailContainer__mediumSectionContainer}>
                  <ManageAttachments
                    card={currentCard}
                    loading={loadingUpload}
                    handleLoading={handleLoadingUpload}
                  />
                </div>

              </div>
              {isDragActive
              && checkIfUserAuthorizedToModifyCard(currentCard, user, currentRoleUser) ? (
                <>
                  <div className={`${styles.box} ${styles.backgroundOverlay}`} />
                  <div className={`${styles.box} ${styles.contentOverlay}`}>
                    <h1>Lepaskan file untuk diunggah.</h1>
                  </div>
                </>
                ) : null}
            </div>
            <div className={styles.cheersSection}>
              {isEmpty(currentCard) ? null : <CheersContainer cheers={currentCard?.cheers} type="cardMain" receiver={currentCard?.creator?._id} primaryParentId={currentCard?._id} />}
            </div>
            <div className={styles.seenSection}>
              {isUserCreator && (
              <SeenInfo
                count={currentCard?.seen?.length}
                onClick={handleShowSeenModal}
                variant="black"
              />
              )}
            </div>
            <div className={styles.typingSection}>
              <UserTyping
                allUserTyping={allUserTypingCard}
                companyMembers={currentCompany?.members}
              />
            </div>
            <div className={styles.CardDetailContainer__mediumSectionContainer}>
              {!showActivities && (
              <InfinityScroll
                dataLength={lists?.length}
                hasMore={checkIfListNeedToLoad()}
                next={handleLoadMoreLists}
                emptyMessage="Belum ada komentar disini..."
                // scrollableTarget="cardModal"
                loader={(
                  <div ref={ref} className="infiniteScroll">
                    <FeedbackSpinnerGeneral size={30} color={Color.yellowAccentCicle} />
                  </div>
                )}
              >
                <CommentsSectionContainer
                  openForm={openCreateCommentForm}
                  onOpenForm={handleOpenCreateCommentForm}
                  comments={lists}
                  type="card"
                  handleShowActivities={handleShowActivities}
                  socket={socketUserTyping?.socket}
                />
              </InfinityScroll>
              )}
              {showActivities && (
                <InfinityScroll
                  dataLength={listActivities?.length}
                  hasMore={checkIfListNeedToLoadActivities()}
                  next={handleLoadMoreListActivities}
                  emptyMessage="Belum ada Aktifitas disini..."
                // scrollableTarget="cardModal"
                  loader={(
                    <div ref={ref} className="infiniteScroll">
                      <FeedbackSpinnerGeneral size={30} color={Color.yellowAccentCicle} />
                    </div>
                )}
                >
                  <ListActivitiesCard
                    variant={serviceTypeRef.card}
                    handleHideActivities={handleHideActivities}
                    lists={listActivities}
                    company={currentCompany}
                    loadings={loadingsActivities}
                  />
                </InfinityScroll>
              )}
            </div>
          </div>
          <div className={styles.CardDetailContainer__sideColumn}>
            <div className={styles.CardDetailContainer__smallSectionContainer}>
              {checkIfUserAuthorizedToModifyCard(currentCard, user, currentRoleUser)
              && <p className={styles.CardDetailContainer__smallTitle}>KELOLA TUGAS</p>}
              {checkIfUserAuthorizedToEditData(currentCard, user,
                currentRoleUser, currentTeamBelowRole) && (
                <div className={styles.buttonAction}>
                  <Button handleClick={handleShowManageMembers} variant="secondary" size="sm" block>
                    <PersonAddIcon />
                    {' '}
                    Anggota
                  </Button>
                  {showManageMembers ? <ManageMultipleMembersContainer card={currentCard} type="card" cancel={handleHideManageMembers} /> : null}
                </div>
              )}
              {checkIfUserAuthorizedToModifyCard(currentCard, user, currentRoleUser) && (
              <div className={styles.buttonAction}>
                <Button handleClick={handleShowManageLabels} variant="secondary" size="sm" block>
                  <LabelIcon />
                  {' '}
                  Label
                </Button>
                {showManageLabels ? (
                  <ManageLabelsContainer
                    cancel={handleHideManageLabels}
                    card={currentCard}
                  />
                ) : null}
              </div>
              )}
              {checkIfUserAuthorizedToModifyCard(currentCard, user, currentRoleUser) && (
              <div className={styles.buttonAction}>
                <Button handleClick={handleShowManageDueDate} variant="secondary" size="sm" block>
                  <EventIcon />
                  {' '}
                  Tenggat
                </Button>
                {showManageDueDate ? (
                  <ManageDueDateContainer
                    card={currentCard}
                    cancel={handleHideManageDueDate}
                  />
                ) : null}
              </div>
              )}
              {checkIfUserAuthorizedToModifyCard(currentCard, user, currentRoleUser) && (
              <div className={styles.buttonAction}>
                <AttachFileContainer handleLoading={handleLoadingUpload} type="card">
                  <Button variant="secondary" size="sm" block>
                    <AttachFileIcon />
                    {' '}
                    Unggah File
                  </Button>
                </AttachFileContainer>
              </div>
              )}
            </div>
            <div className={styles.CardDetailContainer__smallSectionContainer}>
              {checkIfUserAuthorizedToModifyCard(currentCard, user, currentRoleUser)
              && <p className={styles.CardDetailContainer__smallTitle}>AKSI</p>}
              {checkIfUserAuthorizedToModifyCard(currentCard, user, currentRoleUser) && (
              <div className={styles.buttonAction}>
                <Button handleClick={handleShowManageMoveCard} variant="secondary" size="sm" block>
                  <DoubleArrowIcon />
                  {' '}
                  Pindahkan
                </Button>
                {showManageMoveCard
                  ? (
                    <ManageMoveCard
                      cancel={handleHideManageMoveCard}
                      card={currentCard}
                      list={currentCardList}
                      socket={socket}
                    />
                  ) : null}
              </div>
              )}

              {checkIfUserAuthorizedToEditData(currentCard, user,
                currentRoleUser, currentTeamBelowRole) && (
                <div className={styles.buttonAction}>
                  <ChangeAccessCardContainer
                    card={currentCard}
                    handleLoading={handleLoadingChangeAccess}
                    loadings={loadings}
                  >
                    <Button variant="secondary" size="sm" wait="changeAccessCard" loadings={loadings} block>
                      {currentCard?.isPublic ? <LockIcon /> : <LockOpenIcon />}
                      {' '}
                      {currentCard?.isPublic ? 'Rahasiakan' : 'Publik'}
                    </Button>
                  </ChangeAccessCardContainer>
                </div>
              )}

              {checkIfUserAuthorizedToEditData(currentCard, user,
                currentRoleUser, currentTeamBelowRole) && (
                <div className={styles.buttonAction}>
                  <ArchiveButtonContainer
                    card={currentCard}
                    handleLoading={handleLoadingArchive}
                    loadings={loadings}
                  >
                    {currentCard?.archived?.status ? null : (
                      <Button variant="secondary" size="sm" wait="archiveCard" loadings={loadings} block>
                        <ArchiveIcon />
                        {' '}
                        Arsipkan
                      </Button>
                  )}
                  </ArchiveButtonContainer>
                </div>
              )}
            </div>
          </div>
        </div>
        <MyRoleUser
          open={showModalRoleUser}
          onClose={handleHideModalRoleUser}
          featureId={cardId}
          featureType={typeRef.card}
          featureTitle={currentCard?.name}
          roleType={roleTypeRef.feature}
          featureData={currentCard}
        />
        <SeenModal
          open={showSeenModal}
          onClose={handleHideSeenModal}
          type={SeenConstants.typeModule.card}
          itemId={cardId}
        />
      </PageLoading>
    </>
  );
}

export default CardDetailContainer;
