import React, {
  memo, useState, useEffect, useCallback, useContext,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { useSnackbar } from 'notistack';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import styles from './MainChatSection.module.css';
import FetchMessages from './FetchMessages/FetchMessages';
import CreateMessage from './CreateMessage/CreateMessage';
import PersonChatWith from './PersonChatWith/PersonChatWith';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import handleLoadings from '../../../utilities/handleLoadings';
import { ChatActions, CompanyActions } from '../../../actions';
import apiUtil from '../../../utilities/apiUtil';
import { ApiConstants, ChatConstants } from '../../../constants';
import { useDropZoneFile } from '../../../hooks/DropFileHook';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { actionTypes } from '../../../reducers/reducer';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import { useUserTypingChatSocket } from '../../../hooks/UserTypingHooks';
import { useMarkReadNotificationOnPageOpen } from '../../../hooks/NotificationHooks';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

const boxWithSidebarStyles = {
  true: styles.boxSidebarOpen,
  false: '',
};

const MainChatSection = ({ handleChangeChatWith, chatWith }) => {
  const [{
    user, currentChat,
    isSidebarOpen,
    currentCompanies, allUserTypingChat, currentCompany
  }, dispatch] = useContext(GlobalContext);
  const [loadings, setLoadings] = useState([]);
  const [isNeedCheckLoadMore, setCheckLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isChatsLengthAtTheLimitOrAbove = useCallback(
    (chats) => isNumber(chats?.chats?.length)
      && chats?.chats?.length >= ChatConstants.limitChat, [],
  );

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const { companyId, chatId } = params;

  const { socket } = useUserTypingChatSocket({
    companyId,
    chatId,
    userId: user?._id,
  }, dispatch);

  // read notif automatically if there is readNotifId
  useMarkReadNotificationOnPageOpen();

  useEffect(() => {
    if (loadings === undefined) return;
    const checkWait = loadings.filter((loading) => loading === 'listPrivateMessages');

    if (checkWait.length > 0) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [loadings]);

  // React dropzone
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [files, setFiles] = useState();
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length < 1) {
      setFiles(fileRejections);
    } else {
      setFiles(acceptedFiles);
    }
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    isDragActive,
  } = useDropZoneFile({
    onDrop,
  });

  // handle loading upload
  const handleLoadingUpload = (value) => {
    setLoadingUpload(value);
  };

  useEffect(() => {
    if (files === undefined) {
      return;
    }

    const postApiFiles = async () => {
      const data = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        data.append('file', files[i]);
      }

      handleLoadingUpload(true);

      try {
        const result = await apiUtil.post(
          ApiConstants.URL_V1.CHAT_ATTACHMENT({ chatId }),
          data,
          {
            params: {
              companyId,
            },
            ...ApiConstants.TIMEOUT_UPLOAD,
          },
        );

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
      } catch (err) {
        const getErrorMessageDropzoneFile = files?.[0]?.errors?.[0];
        const messageError = getErrorMessageDropzoneFile === undefined
          ? err : getErrorMessageDropzoneFile;
        const status = handleStatusMsg(messageError, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        handleLoadingUpload(false);
      }
    };
    postApiFiles();
  }, [files]);

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (!chatId) return;
    if (!companyId) return;

    const startLoadings = handleLoadings('listPrivateMessages', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiChatDetail = async () => {
      try {
        const resultV2 = await ChatActions.initiateChatV2({ chatId, companyId }, dispatch);
        await CompanyActions.getAndSaveCompanyAndLastOpenedCompany(
          { companyId, companies: currentCompanies, userId: user?._id }, dispatch,
        );

        if (isChatsLengthAtTheLimitOrAbove(resultV2)) {
          setCheckLoadMore(true);
        } else {
          setCheckLoadMore(false);
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('listPrivateMessages', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchApiChatDetail();
  }, [location]);

  useEffect(() => {
    if (currentChat.members === undefined) return;

    const memberWithoutUser = currentChat.members.filter((member) => member._id !== user._id);

    handleChangeChatWith(memberWithoutUser[0]);
  }, [currentChat]);

  // Change default height div create message
  const defaultHeightDivCreateMessage = 55;
  const [style, setStyle] = useState({
    height: `calc(100vh - (34px + 55px + ${defaultHeightDivCreateMessage}px))`,
  });

  const handleChangeHeightDivMessagesSection = (height) => {
    const newStyle = {
      height: `calc(100vh - (34px + 55px + ${height}px))`,
    };

    setStyle(newStyle);
  };

  return (
    <>
      <div className={containerWithSidebarStyles[isSidebarOpen]} style={style}>
        <div className={styles.header}>
          <PersonChatWith
            allUserTypingChat={allUserTypingChat}
            companyMembers={currentCompany?.members}
            user={chatWith && chatWith?.length > 0 ? chatWith[0] : null}
          />
        </div>
        <div className={styles.dropzone} {...getRootProps()}>
          <div className={`${styles.box} ${boxWithSidebarStyles[isSidebarOpen]}`}>
            <div className={styles.messages} style={style}>
              <OverlayButton
                spinnerSize="lg"
                wait="listPrivateMessages"
                loadings={loadings}
                disableChildrenOnWait
                noBackgroundOverlay
                style={style}
              >
                <FetchMessages
                  isNeedCheckLoadMore={isNeedCheckLoadMore}
                  checkLengthChatToLimit={isChatsLengthAtTheLimitOrAbove}
                />
              </OverlayButton>
            </div>
            {isDragActive ? (
              <>
                <div className={`${styles.box} ${styles.backgroundOverlay} ${boxWithSidebarStyles[isSidebarOpen]}`} />
                <div className={`${styles.box} ${styles.contentOverlay} ${boxWithSidebarStyles[isSidebarOpen]}`}>
                  <h1>Lepaskan file untuk diunggah.</h1>
                </div>
              </>
            ) : null}
            {loadingUpload ? (
              <>
                <div className={`${styles.box} ${styles.backgroundOverlay} ${boxWithSidebarStyles[isSidebarOpen]}`} />
                <div className={`${styles.box} ${styles.contentOverlay} ${boxWithSidebarStyles[isSidebarOpen]}`}>
                  <div className={styles.loadingContainer}>
                    <Spinner animation="border" size="sm" variant="warning" />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className={styles.create}>
          {isLoading ? null : (
            <CreateMessage
              handleChangeHeightDivMessagesSection={handleChangeHeightDivMessagesSection}
              handleLoading={handleLoadingUpload}
              chatWith={chatWith}
              parentLoadings={loadings}
              parentWait="listPrivateMessages"
              socket={socket}
            />
          )}
        </div>
      </div>
    </>
  );
};

MainChatSection.propTypes = {
  handleChangeChatWith: PropTypes.func.isRequired,
  chatWith: PropTypes.object.isRequired,
};

export default memo(MainChatSection);
