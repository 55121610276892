import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './DrawerLogoSection.module.css';
import logoImage from '../../../assets/Logo/logo-new.png';
import Color from '../../../themes/colors';
import { handleCloseSidebar, handleOpenSidebar } from '../../../actions/SidebarActions';
import { DisplayAvatarMemberWithOnline } from '../../UI_V2/Display/Avatar';
import LinkNoDecor from '../../LinkNoDecor/LinkNoDecor';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { DisplayBadgeCounter } from '../../UI_V2/Display/Badge';

const DrawerLogoSection = ({
  params,
  handleShowUserMenu,
}) => {
  const [{
    isSidebarOpen,
    user,
    currentCompany,
    counterNotif,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');

  const { companyId } = params;

  return (
    <div className={styles.container}>
      <div className={styles.drawerAndLogo}>
        <LinkNoDecor to={companyId ? `/companies/${companyId}` : '/'}>
          <div className={styles.logoIcon}>
            <LazyLoadImage
              alt="logo"
              src={currentCompany?.logo ? currentCompany?.logo : logoImage}
            />
          </div>
        </LinkNoDecor>
      </div>
      <div className={styles.rightColumn}>
        <div
          className={styles.drawerIcon}
          onClick={isSidebarOpen
            ? () => handleCloseSidebar({}, dispatch)
            : () => handleOpenSidebar({}, dispatch)}
        >
          <ViewSidebarOutlinedIcon />
          {/* <DisplayBadgeCounter
              badgeContent={counterNotif.nonChat
                 + counterNotif.chat + counterCompaniesNotif.totalCounter}
              variant="dot"
              height="10px"
              minWidth="10px"
              customStyle={{ top: -3, right: 2 }}
            /> */}
        </div>
        <div className={styles.memberWithCounter} onClick={handleShowUserMenu}>
          <DisplayBadgeCounter
            badgeContent={counterNotif.chat}
            customStyle={{ top: 8, right: -12 }}
          />
          <DisplayAvatarMemberWithOnline
            src={user?.photoUrl}
            positionPopUp="topRight"
            size="sm"
            marginRight="0px"
            userId={user?._id}
            enableOnClickCustom
          />
        </div>
      </div>
    </div>
  );
};

DrawerLogoSection.propTypes = {
  params: PropTypes.object,
  handleShowUserMenu: PropTypes.func,
};

DrawerLogoSection.defaultProps = {
  params: { companyId: null, teamId: null },
  handleShowUserMenu: () => null,
};

export default memo(DrawerLogoSection);
