import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@mui/icons-material/Sort';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './CardListItemDueDate.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import CardListColumnBox from '../../CardListColumnBox/CardListColumnBox';
import { DisplayDateBox, DisplayDateThinBox } from '../../../UI_V2/Display/Date';
import { useDelayShowHideHandler } from '../../../../hooks/HelperHooks';
import ManageDueDateContainer from '../../../../pages/ManageDueDateContainer/ManageDueDateContainer';

const CardListItemDueDate = ({
  item,
  widthPercentage,
  variantBorder,
  setSelected,
  triggerScrollIntoView,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const hasDueDate = item.dueDate;
  const [
    showManageDueDate,
    handleShowManageDueDate,
    handleHideManageDueDate,
  ] = useDelayShowHideHandler();

  const handleClickManageDueDate = () => {
    handleShowManageDueDate();
    setSelected(item._id);

    triggerScrollIntoView();
  };

  return (
    <CardListColumnBox
      variantBorder={variantBorder}
      widthPercentage={widthPercentage}
      variantCursor="pointer"
      onClick={showManageDueDate ? () => null : handleClickManageDueDate}
    >
      <div
        className={styles.container}
      >
        {hasDueDate && !isMobile && (
        <DisplayDateBox
          date={item.dueDate}
          complete={item.complete}
          changeColorDate
          icon
          dateStyle="MonthDate"
        />
        )}
        {hasDueDate && isMobile && (
        <DisplayDateThinBox
          dueDate={item.dueDate}
          completeStatus={item.complete}
        />
        )}
        {showManageDueDate
        && (
        <ManageDueDateContainer
          cancel={handleHideManageDueDate}
          card={item}
        />
        )}
      </div>
    </CardListColumnBox>
  );
};

CardListItemDueDate.propTypes = {
  item: PropTypes.object,
  widthPercentage: PropTypes.number,
  variantBorder: PropTypes.string.isRequired,
  setSelected: PropTypes.func,
  triggerScrollIntoView: PropTypes.func,
};

CardListItemDueDate.defaultProps = {
  item: {},
  widthPercentage: 100,
  setSelected: () => null,
  triggerScrollIntoView: () => null,
};

export default memo(CardListItemDueDate);
