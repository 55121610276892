import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardSnapshot.module.css';
import Label from '../../../../components/UI/Label/Label';
import ImageMember from '../../../../components/UI/ImageMember/ImageMember';
import DateBox from '../../../../components/UI/DateBox/DateBox';
import DescIcon from '../../../../components/UI/DescIcon/DescIcon';
import CommentIcon from '../../../../components/UI/CommentIcon/CommentIcon';
import AttachmentIcon from '../../../../components/UI/AttachmentIcon/AttachmentIcon';
import PrivateIcon from '../../../../components/UI/PrivateIcon/PrivateIcon';

const CardSnapshot = ({ card }) => {
  const {
    labels, name, dueDate, members, comments, attachments, desc, list,
  } = card;
  return (
    <div className={styles.SmallCard}>
      <div className={styles.labelsSection}>
        {labels !== undefined ? labels.map((label) => (
          <Label color={label.color.name} name={label.name} />
        )) : null}
      </div>
      <div className={styles.cardTitleSection}>
        <h1>
          <PrivateIcon data={card} size="small" />
          {' '}
          {name}
        </h1>
      </div>

      <div className={styles.othersPropertiesSection}>
        {dueDate !== undefined
          ? <DateBox complete={card.complete} date={dueDate} icon changeColorDate /> : null}
        <DescIcon desc={desc} />
        <CommentIcon comments={comments} />
        <AttachmentIcon attachments={attachments} />
      </div>
      <div className={styles.imageListSection}>
        <div className={styles.imageMemberSection}>
          {members.map((member) => (
            <ImageMember photoUrl={member.photoUrl} floatRight />
          ))}
        </div>
        <div className={styles.listTitle}>
          <h1>{list.name}</h1>
        </div>
      </div>

    </div>
  );
};

CardSnapshot.propTypes = {
  card: PropTypes.object.isRequired,
};

export default CardSnapshot;
