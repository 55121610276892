import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './File.module.css';
import PrivateIcon from '../../../components/UI/PrivateIcon/PrivateIcon';
import { AttachmentActions } from '../../../actions';

const File = ({ file, clicked, big }) => {
  const [title, setTitle] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isImage, setIsImage] = useState();
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    if (file.title === undefined) return;

    let tempTitle;
    if (file.title.length < 40) {
      tempTitle = file.title;
    } else {
      tempTitle = `${file.title.slice(0, 40)}...`;
    }

    setTitle(tempTitle);

    const urlFile = file.url?.charAt(0) === '/'
      ? process.env.REACT_APP_PRIMARY_API_URL + file.url
      : file.url;

    setFileUrl(urlFile);

    const result = AttachmentActions.getMimeTypeUrl({ mimeType: file.mimeType, fileUrl: urlFile });

    setImageUrl(result?.imageUrl);
    setIsImage(result?.isImage);
  }, [file]);

  return (
    <>
      {big ? (
        <div className={styles.mainBig} onClick={clicked}>
          <div className={styles.main__icon}>
            <a href={fileUrl} target="_blank" rel="noreferrer">
              <img src={imageUrl} style={isImage ? {} : { maxWidth: '70px' }} />
            </a>
          </div>
          <div className={styles.main__title}>
            <h1>
              <PrivateIcon data={file} />
              {' '}
              {file.title}
            </h1>
            <a href={fileUrl} target="_blank" rel="noreferrer">
              Unduh
            </a>
          </div>
        </div>
      ) : (
        <div className={styles.main} onClick={clicked}>
          <div className={styles.main__icon}>
            <img src={imageUrl} style={{ width: '70px' }} />
          </div>
          <div className={styles.main__title}>
            <h1>
              <PrivateIcon data={file} size="small" />
              {' '}
              <h1>{title}</h1>
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

File.propTypes = {
  file: PropTypes.object.isRequired,
  clicked: PropTypes.func.isRequired,
  big: PropTypes.bool.isRequired,
};

export default File;
