import { useDropzone } from 'react-dropzone';

function useDropZoneFile({ onDrop, noDragEventsBubbling = false }) {
  const maxSize = 180 * 1024 * 1024;

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    maxSize,
    noDragEventsBubbling,
  });

  return {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  };
}

export {
  useDropZoneFile,
};
