import React, {
  useState, useEffect, useContext, useRef, memo,
} from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LabelIcon from '@mui/icons-material/Label';
import EventIcon from '@mui/icons-material/Event';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArchiveIcon from '@mui/icons-material/Archive';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import TitleIcon from '@mui/icons-material/Title';
import CommentIcon from '@mui/icons-material/Comment';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import ArchiveButtonContainer from '../../CardDetailContainer/ArchiveButtonContainer/ArchiveButtonContainer';
import AttachFileContainer from '../../AttachFileContainer/AttachFileContainer';
import handleLoadings from '../../../utilities/handleLoadings';
import { actionTypes } from '../../../reducers/reducer';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import OverlayButton from '../../../components/UI/Button/OverlayButton/OverlayButton';
import styles from './EditCardMenu.module.css';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import ChangeAccessCardContainer from '../../CardDetailContainer/ChangeAccessCardContainer/ChangeAccessCardContainer';
import { checkIfUserAuthorizedToEditData, checkIfUserAuthorizedToModifyCard } from '../../../actions/UserActions';

const EditCardMenu = ({
  card, list, handleCancelAllCardMenu, handleShowMove, handleShowLabel,
  handleShowDueDate, handleShowMember, handleShowNote, handleShowName,
  handleShowQuickComment, selectIdVariant, disableScrollIntoView, forceTeamId,
  disableEditMoveCard,
  disableEditLabels,
  disableEditMembers,
  disableEditDueDate,
}) => {
  const [{ user, currentRoleUser }, dispatch] = useContext(GlobalContext);
  const itemRef = useRef(null);

  const [loadings, setLoadings] = useState([]);

  useEffect(() => {
    if (itemRef.current && !disableScrollIntoView) {
      itemRef.current.scrollIntoView(
        {
          block: 'center',
          behavior: 'smooth',
        },
      );
    }
  }, []);

  const handleLoadingUpload = (status) => {
    if (status) {
      const startLoadings = handleLoadings('attachFileCardMenu', [...loadings], 'start');
      setLoadings([...startLoadings]);
    } else {
      const endLoadings = handleLoadings('attachFileCardMenu', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleLoadingArchive = (status) => {
    if (status) {
      const startLoadings = handleLoadings('archiveButtonCardMenu', [...loadings], 'start');
      setLoadings([...startLoadings]);
    } else {
      const endLoadings = handleLoadings('archiveButtonCardMenu', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  const handleLoadingChangeAccess = (status) => {
    if (status) {
      const startLoadings = handleLoadings('changeAccessButtonCardMenu', [...loadings], 'start');
      setLoadings([...startLoadings]);
    } else {
      const endLoadings = handleLoadings('changeAccessButtonCardMenu', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_EDIT_CARD_ITEMS,
      card,
      list,
    });
  }, [card, list]);

  const isSelectVariantCardId = selectIdVariant === 'cardId';

  return (
    <>
      <div ref={itemRef} id="editCardMenuButton" className={styles.container}>
        {checkIfUserAuthorizedToModifyCard(card, user, currentRoleUser) && !disableEditMoveCard && (
        <ButtonDiv id="editCardMenuButton__Move" onClick={() => handleShowMove(isSelectVariantCardId ? card._id : list._id)} className={styles.menu}>
          <DoubleArrowIcon id="editCardMenuButton__Move__icon" className={styles.icon} />
          <p id="editCardMenuButton__Move__text">Pindah Tugas</p>
        </ButtonDiv>
        )}
        <ButtonDiv id="editCardMenuButton__Move" onClick={() => handleShowQuickComment(isSelectVariantCardId ? card._id : list._id)} className={styles.menu}>
          <CommentIcon id="editCardMenuButton__Move__icon" className={styles.icon} />
          <p id="editCardMenuButton__Move__text">Beri Komentar</p>
        </ButtonDiv>
        {checkIfUserAuthorizedToEditData(card, user, currentRoleUser)
        && !disableEditMembers && (
        <ButtonDiv id="editCardMenuButton__Member" onClick={() => handleShowMember(isSelectVariantCardId ? card._id : list._id)} className={styles.menu}>
          <PersonAddIcon id="editCardMenuButton__Member__icon" className={styles.icon} />
          <p id="editCardMenuButton__Member__text">Ubah Anggota</p>
        </ButtonDiv>
        )}
        {checkIfUserAuthorizedToModifyCard(card, user, currentRoleUser) && !disableEditLabels && (
        <ButtonDiv id="editCardMenuButton__Label" onClick={() => handleShowLabel(isSelectVariantCardId ? card._id : list._id)} className={styles.menu}>
          <LabelIcon id="editCardMenuButton__Label__icon" className={styles.icon} />
          <p id="editCardMenuButton__Label__text">Ubah Label</p>
        </ButtonDiv>
        )}
        {checkIfUserAuthorizedToModifyCard(card, user, currentRoleUser) && !disableEditDueDate && (
        <ButtonDiv id="editCardMenuButton__Date" onClick={() => handleShowDueDate(isSelectVariantCardId ? card._id : list._id)} className={styles.menu}>
          <EventIcon id="editCardMenuButton__Date__icon" className={styles.icon} />
          <p id="editCardMenuButton__Date__text">Ubah Tenggat</p>
        </ButtonDiv>
        )}
        {checkIfUserAuthorizedToModifyCard(card, user, currentRoleUser) && (
        <ButtonDiv id="editCardMenuButton__Notes" onClick={() => handleShowNote(isSelectVariantCardId ? card._id : list._id)} className={styles.menu}>
          <TextFormatIcon id="editCardMenuButton__Notes__icon" className={styles.icon} />
          <p id="editCardMenuButton__Notes__text">Ubah Catatan</p>
        </ButtonDiv>
        )}

        {checkIfUserAuthorizedToEditData(card, user, currentRoleUser) && (
        <ButtonDiv id="editCardMenuButton__Name" onClick={() => handleShowName(isSelectVariantCardId ? card._id : list._id)} className={styles.menu}>
          <TitleIcon id="editCardMenuButton__Name__icon" className={styles.icon} />
          <p id="editCardMenuButton__Name__text">Ubah Nama</p>
        </ButtonDiv>
        )}
        {checkIfUserAuthorizedToModifyCard(card, user, currentRoleUser) && (
        <div className={styles.inline}>
          <OverlayButton wait="attachFileCardMenu" loadings={loadings}>
            <AttachFileContainer card={card} forceTeamId={forceTeamId} handleLoading={handleLoadingUpload} type="card">
              <ButtonDiv id="editCardMenuButton__Attach" className={styles.menu}>
                <AttachFileIcon id="editCardMenuButton__Attach__icon" className={styles.icon} />
                <p id="editCardMenuButton__Attach__text">Unggah File</p>
              </ButtonDiv>
            </AttachFileContainer>
          </OverlayButton>
        </div>
        )}

        {checkIfUserAuthorizedToEditData(card, user, currentRoleUser) && (
        <div className={styles.inline}>
          <OverlayButton wait="changeAccessButtonCardMenu" loadings={loadings}>
            <ChangeAccessCardContainer
              card={card}
              handleLoading={handleLoadingChangeAccess}
            >
              <ButtonDiv id="editCardMenuButton__ChangeAccess" className={styles.menu}>
                {
                  card?.isPublic
                    ? <LockIcon id="editCardMenuButton__ChangeAccess_icon" className={styles.icon} />
                    : <LockOpenIcon id="editCardMenuButton__ChangeAccess_icon" className={styles.icon} />
                }
                <p id="editCardMenuButton__ChangeAccess_text">
                  {card?.isPublic ? 'Jadikan Rahasia' : 'Jadikan Publik'}
                </p>
              </ButtonDiv>
            </ChangeAccessCardContainer>
          </OverlayButton>
        </div>
        )}

        {checkIfUserAuthorizedToEditData(card, user, currentRoleUser) && (
        <div className={styles.inline}>
          <OverlayButton wait="archiveButtonCardMenu" loadings={loadings}>
            <ArchiveButtonContainer
              card={card}
              handleLoading={handleLoadingArchive}
            >
              <ButtonDiv id="editCardMenuButton__Archive" className={styles.menu}>
                <ArchiveIcon id="editCardMenuButton__Archive__icon" className={styles.icon} />
                <p id="editCardMenuButton__Archive__text">Arsipkan</p>
              </ButtonDiv>
            </ArchiveButtonContainer>
          </OverlayButton>
        </div>
        )}
      </div>
    </>
  );
};

EditCardMenu.propTypes = {
  card: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  handleCancelAllCardMenu: PropTypes.func.isRequired,
  handleShowMove: PropTypes.func.isRequired,
  handleShowLabel: PropTypes.func.isRequired,
  handleShowDueDate: PropTypes.func.isRequired,
  handleShowMember: PropTypes.func.isRequired,
  handleShowNote: PropTypes.func.isRequired,
  handleShowName: PropTypes.func.isRequired,
  handleShowQuickComment: PropTypes.func.isRequired,
  selectIdVariant: PropTypes.oneOf(['listId', 'cardId']),
  disableScrollIntoView: PropTypes.bool,
  forceTeamId: PropTypes.string,
  disableEditDueDate: PropTypes.bool,
  disableEditMembers: PropTypes.bool,
  disableEditLabels: PropTypes.bool,
  disableEditMoveCard: PropTypes.bool,
};

EditCardMenu.defaultProps = {
  selectIdVariant: 'listId',
  disableScrollIntoView: false,
  forceTeamId: undefined,
  disableEditDueDate: false,
  disableEditMembers: false,
  disableEditLabels: false,
  disableEditMoveCard: false,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const { card, list } = nextProps;
  return isEqual(prevProps.card, card)
  && isEqual(prevProps.list, list);
};

export default memo(EditCardMenu, isComponentDataEqual);
