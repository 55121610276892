import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './CardBottleneck.module.css';
import Color from '../../../../../../themes/colors';
import SmallCard from '../../../../../ListContainer/SmallCard/SmallCard';
import TitleChart from '../../../ChartUserReport/TitleChart/TitleChart';
import { DisplayTextBody } from '../../../../../../components/UI_V2/Display/Text';
import { limitChar } from '../../../../../../utilities/stringUtil';
import { isDateBeforeNow } from '../../../../../../utilities/dateUtil';

const CardBottleneck = ({
  title,
  card,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <DisplayTextBody
        mode="12"
        color={Color.gray3}
      >
        {title}
      </DisplayTextBody>
    </div>
    <div className={styles.card}>
      {card?._id && (
      <SmallCard
        name={limitChar(card?.name, 20)}
        desc={card?.desc}
        labels={card?.labels}
        members={card?.members}
        dueDate={card?.dueDate}
        comments={card?.comments}
        attachments={card?.attachments}
        archived={card?.archived?.status}
        complete={card?.complete}
        isCardPublic={card?.isPublic}
        cardId={card?._id}
        teamId={card?.team}
        isCardBlur={card?.isForbidden}
        isCardOngoingButOverdue={isDateBeforeNow(card.dueDate, new Date())}
      />
)}
      {!card?._id && <DisplayTextBody>Belum ada data</DisplayTextBody>}
    </div>
  </div>
);

CardBottleneck.propTypes = {
  title: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
};

CardBottleneck.defaultProps = {};

export default memo(CardBottleneck);
