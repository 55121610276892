import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AttachmentFile.module.css';
import { AttachmentActions } from '../../../../actions';

const AttachmentFile = ({ attachment }) => {
  const [name, setName] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isImage, setIsImage] = useState();

  useEffect(() => {
    if (attachment.name === undefined) return;

    let tempName;
    if (attachment.name.length < 40) {
      tempName = attachment.name;
    } else {
      tempName = `${attachment.name.slice(0, 40)}...`;
    }

    setName(tempName);

    const attachmentUrl = attachment.url?.charAt(0) === '/'
      ? process.env.REACT_APP_PRIMARY_API_URL + attachment.url
      : attachment.url;
    const result = AttachmentActions.getMimeTypeUrl(
      { mimeType: attachment.mimeType, fileUrl: attachmentUrl },
    );

    setImageUrl(result?.imageUrl);
    setIsImage(result?.isImage);
  }, [attachment]);

  return (
    <div className={styles.main}>
      <div className={styles.main__icon}>
        <img src={imageUrl} style={isImage ? {} : { maxWidth: '70px', maxHeight: '50px' }} />
      </div>
    </div>

  );
};

AttachmentFile.propTypes = {
  attachment: PropTypes.object.isRequired,
};

export default AttachmentFile;
