import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Switch } from '@mui/material';
import styles from './ModalCreateCardWithTeamListSelection.module.css';
import { FeedbackDialogGeneral } from '../../UI_V2/Feedback/Dialog';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import handleLoadings from '../../../utilities/handleLoadings';
import { CardActions } from '../../../actions';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { DisplayTextBody, DisplayTextHeadline } from '../../UI_V2/Display/Text';
import { InputTextFieldMain } from '../../UI_V2/Input/TextField';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import Color from '../../../themes/colors';
import SelectTeam from './SelectTeam/SelectTeam';
import SelectList from './SelectList/SelectList';
import SelectDueDate from './SelectDueDate/SelectDueDate';

function ModalCreateCardWithTeamListSelection({
  open, onClose, initialDueDate, disableSelectTeam,
}) {
  const [{ teams, currentTeam }] = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [isAccessPrivate, setAccessPrivate] = useState(false);
  const [selectedListId, setSelectedListId] = useState();
  const [selectedTeamId, setSelectedTeamId] = useState();
  const [selectedBoardId, setSelectedBoardId] = useState();
  const [dueDate, setDueDate] = useState(initialDueDate);
  const [newCard, setNewCard] = useState({
    name: '',
  });

  const params = useParams();
  const { companyId } = params;

  const clearValue = () => {
    setNewCard({
      name: '',
    });
    setAccessPrivate(false);
  };

  const handleChanged = (event) => {
    const { name, value } = event.target;

    setNewCard((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleChangeAccess = useCallback((event) => {
    // event.preventDefault();
    setAccessPrivate(event?.target?.checked);
  }, []);

  const handleClicked = async () => {
    try {
      const startLoadings = handleLoadings('createCard', [...loadings], 'start');
      setLoadings([...startLoadings]);

      if (!newCard.name || newCard.name.length < 1) {
        enqueueSnackbar('Nama tugas tidak boleh kosong', {
          variant: 'error',
        });
        return;
      }

      if (!selectedTeamId) {
        enqueueSnackbar('Kamu belum pilih tim. Buat atau gabung dengan tim dulu.', {
          variant: 'error',
        });
        return;
      }

      if (!selectedListId) {
        enqueueSnackbar('Kamu belum pilih list. Buat atau minta managermu buatkan list dulu.', {
          variant: 'error',
        });
        return;
      }

      if (!selectedBoardId) {
        enqueueSnackbar('Nama boardId tidak boleh kosong', {
          variant: 'error',
        });
        return;
      }

      const payload = {
        boardId: selectedBoardId,
        listId: selectedListId,
        companyId,
        teamId: selectedTeamId,
        cardName: newCard.name,
        isAccessPrivate,
        dueDate,
      };

      const result = await CardActions.createCard(payload);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      clearValue();
      onClose();
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('createCard', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    setDueDate(initialDueDate);
  }, [initialDueDate]);

  return (
    <FeedbackDialogGeneral
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <div className={styles.container}>
        <div className={styles.title}>
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            decoration="bold"
          >
            Buat Tugas
          </DisplayTextHeadline>
        </div>
        <div className={styles.formSection}>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Nama Tugas
              </DisplayTextBody>
            </div>
            <div className={styles.formArea}>
              <InputTextFieldMain
                placeholder="Posting 5 konten, buat draft keuangan 2023, handle klien X"
                name="name"
                autoFocus
                onChange={(event) => handleChanged(event)}
                value={newCard.name}
              />
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Tim
              </DisplayTextBody>
            </div>
            <div className={styles.teamSelectionSection}>
              <SelectTeam
                handleSelectTeamId={setSelectedTeamId}
                disableSelectTeam={disableSelectTeam}
              />
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                List
              </DisplayTextBody>
            </div>
            <div className={styles.teamSelectionSection}>
              <SelectList
                handleSelectBoardId={setSelectedBoardId}
                handleSelectListId={setSelectedListId}
              />
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.formText}>
              <DisplayTextBody
                decoration="bold"
              >
                Tenggat waktu
              </DisplayTextBody>
            </div>
            <div className={styles.teamSelectionSection}>
              <SelectDueDate
                dueDate={dueDate}
                handleSetDueDate={setDueDate}
              />
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.cardAccessSection}>
              <Switch
                color="primary"
                size="small"
                onChange={handleChangeAccess}
              />
              <div className={styles.textCard}>
                <DisplayTextBody>Tugas Rahasia</DisplayTextBody>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.button}>
          <InputButtonMain
            handleClick={handleClicked}
            // border="rounded"
            wide="block"
            loadings={loadings}
            wait="createCard"
          >
            Buat
          </InputButtonMain>
        </div>
      </div>
    </FeedbackDialogGeneral>
  );
}

ModalCreateCardWithTeamListSelection.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialDueDate: PropTypes.object,
  disableSelectTeam: PropTypes.bool,
};

ModalCreateCardWithTeamListSelection.defaultProps = {
  initialDueDate: undefined,
  disableSelectTeam: false,
};

export default memo(ModalCreateCardWithTeamListSelection);
