import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import styles from './General.module.css';
import Color from '../../../../themes/colors';

const FeedbackSpinnerGeneral = ({
  size, variant, value, color, thickness,
}) => (
  <CircularProgress
    sx={{ color }}
    size={size}
    variant={variant}
    value={value}
    thickness={thickness}
  />
);

FeedbackSpinnerGeneral.propTypes = {
  size: PropTypes.number,
  variant: PropTypes.oneOf(['indeterminate', 'determinate']),
  value: PropTypes.number,
  color: PropTypes.string,
  thickness: PropTypes.number,
};

FeedbackSpinnerGeneral.defaultProps = {
  size: 24,
  variant: 'indeterminate',
  value: 0,
  color: Color.white,
  thickness: 6,
};

export default memo(FeedbackSpinnerGeneral);
