import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './DueDate.module.css';
import DateBox from '../../../components/UI/DateBox/DateBox';
import ManageDueDateContainer from '../../ManageDueDateContainer/ManageDueDateContainer';
import { checkIfUserAuthorizedToModifyCard } from '../../../actions/UserActions';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';

const DueDate = ({ card }) => {
  const [{ user, currentRoleUser }] = useContext(GlobalContext);
  const [showDueDateContainer,
    handleShowDueDateContainer, handleHideDueDateContainer] = useDelayShowHideHandler();

  const handleClick = () => {
    handleShowDueDateContainer();
  };

  const handleCancel = () => {
    handleHideDueDateContainer();
  };
  return (
    <>
      {card.dueDate !== undefined
        ? <p className={styles.DueDate__smallTitle}>TENGGAT WAKTU</p> : null}

      {card.dueDate !== undefined
        ? (
          <>
            <DateBox
              complete={card.complete}
              clicked={checkIfUserAuthorizedToModifyCard(card, user, currentRoleUser)
                ? () => handleClick()
                : () => null}
              date={card.dueDate}
              expand
              icon
              detail
              changeColorDate
            />
            {showDueDateContainer
              ? <ManageDueDateContainer card={card} cancel={handleCancel} position="centerRight" />
              : null}
          </>
        ) : null}

    </>
  );
};

DueDate.propTypes = {
  card: PropTypes.object.isRequired,
};

export default DueDate;
