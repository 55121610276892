import { isArray } from 'lodash';
import { CompanyActions, TeamActions } from '.';
import { setStoreCurrentTeam } from '../utilities/localStorage';
import { pageType } from '../constants/PageConstants';
import { limitChar } from '../utilities/stringUtil';

/*
  Method
*/

function generateRoutesAndTitle({ type, data }) {
  const {
    profileUser,
    companyId,
    currentCompany,
    currentTeam,
    currentCard,
    chatId,
    currentBlast,
    boardId,
    blastId,
    currentBoard,
    currentBlastPost,
    currentCheckIn,
    currentCheckInQuestion,
    currentSchedule,
    currentScheduleEvent,
    currentOccurrence,
    currentBucket,
    currentBucketDoc,
    currentBucketFile,
    bucketDocPath,
    locationStateFrom,
    currentComment,
    chatWith,
    userId,
    initialMyTaskCalendarView,
  } = data;
  let routes = [];
  let title = '';
  let url = '';

  let newBucketDocPath = [];
  if (isArray(bucketDocPath)) {
    newBucketDocPath = bucketDocPath.map((elem) => {
      const item = {
        ...elem,
      };
      if (item.name === 'Docs & Files') item.name = 'Dokumen & File';
      return item;
    });
  }
  switch (type) {
    case pageType.home:
      routes = [
        {
          label: 'home',
          pathname: '#',
          name: 'Beranda',
        },
        {
          label: 'company',
          pathname: '#',
          name: currentCompany?.name || 'Perusahaan',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}`;
      title = currentCompany?.name || 'Beranda';
      break;
    case pageType.companyMembers:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'company',
          pathname: '#',
          name: currentCompany?.name || 'Perusahaan',
        },
        {
          pathname: '#',
          name: 'Anggota',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/settings/members`;
      title = `Anggota - ${currentCompany?.name}` || 'Perusahaan';
      break;
    case pageType.companySettings:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'company',
          pathname: '#',
          name: currentCompany?.name || 'Perusahaan',
        },
        {
          pathname: '#',
          name: 'Pengaturan',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/settings/edit`;
      title = `Pengaturan - ${currentCompany?.name}` || 'Perusahaan';
      break;
    case pageType.teams:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: '#',
          name: currentTeam.name || 'Team',
        },
      ];

      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam?._id}`;
      title = currentTeam.name || 'Team';
      break;
    case pageType.teamsSettings:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam?._id}`,
          name: currentTeam.name || 'Team',
        },
        {
          pathname: '#',
          name: 'Pengaturan Tim',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam?._id}/settings`;
      title = `Pengaturan Tim - ${currentTeam?.name}`;
      break;
    case pageType.reports:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam?._id}`,
          name: currentTeam.name || 'Team',
        },
        {
          pathname: '#',
          name: 'Laporan Kinerja',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam?._id}/reports/members/${userId}`;
      title = `Laporan Kinerja - ${currentTeam?.name}`;
      break;

    case pageType.myTasksList:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          pathname: '#',
          name: 'Semua Tugas - List',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/my-tasks/list/all`;
      title = 'Semua Tugas - List';
      break;

    case pageType.myTasksCalendar:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          pathname: '#',
          name: 'Semua Tugas - Kalender',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/my-tasks/calendar/all?initialCalendarView=${initialMyTaskCalendarView}`;
      title = 'Semua Tugas - Kalender';
      break;
    case pageType.cheers:
      if (locationStateFrom) {
        routes = [
          {
            label: 'home',
            pathname: `/companies/${companyId || currentCompany?._id}`,
            name: 'Beranda',
          },
          {
            pathname: '#',
            name: 'Ringkasan Cheers',
          },
          {
            pathname: locationStateFrom,
            name: 'Kembali',
          },
        ];
      } else {
        routes = [
          {
            label: 'home',
            pathname: `/companies/${companyId || currentCompany?._id}`,
            name: 'Beranda',
          },
          {
            pathname: '#',
            name: 'Ringkasan Cheers',
          },
        ];
      }
      url = `/companies/${companyId || currentCompany?._id}/cheers`;
      title = 'Ringkasan Cheers';
      break;
    case pageType.profiles:
      if (locationStateFrom) {
        routes = [
          {
            label: 'home',
            pathname: `/companies/${companyId || currentCompany?._id}`,
            name: 'Beranda',
          },
          {
            pathname: '#',
            name: 'Profil',
          },
          {
            pathname: locationStateFrom,
            name: 'Kembali',
          },
        ];
      } else {
        routes = [
          {
            label: 'home',
            pathname: `/companies/${companyId || currentCompany?._id}`,
            name: 'Beranda',
          },
          {
            pathname: '#',
            name: 'Profil',
          },
        ];
      }
      url = `/companies/${companyId || currentCompany?._id}/profiles/${profileUser?._id}`;
      title = `Profil ${profileUser?.fullName}`;
      break;
    case pageType.notifications:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          pathname: '#',
          name: 'Notifikasi',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/notifications`;
      title = 'Semua Notifikasi';
      break;
    case pageType.chats:
      if (chatWith && chatWith?.length > 0) {
        routes = [
          {
            label: 'home',
            pathname: `/companies/${companyId || currentCompany?._id}`,
            name: 'Beranda',
          },
          {
            pathname: `/companies/${companyId || currentCompany?._id}/chats`,
            name: 'Chat Pribadi',
          },
          {
            pathname: '#',
            name: chatWith[0]?.fullName,
          },
        ];
      } else {
        routes = [
          {
            label: 'home',
            pathname: `/companies/${companyId || currentCompany?._id}`,
            name: 'Beranda',
          },
          {
            pathname: '#',
            name: 'Chat Pribadi',
          },
        ];
      }
      url = `/companies/${companyId || currentCompany?._id}/chats/${chatId}`;
      title = chatWith?.length > 0 ? `Chat Pribadi: ${chatWith?.[0]?.fullName}` : 'Chat Pribadi';
      break;
    case pageType.groupChats:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: '#',
          name: 'Chat Grup',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam?._id}/group-chats/${currentTeam?.groupChat?._id}`;
      title = `Chat Grup - ${currentTeam.name}`;
      break;
    case pageType.blasts:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: '#',
          name: 'Pengumuman',
        },
      ];

      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam?._id}/blasts/${currentTeam?.blast?._id}`;
      title = 'Pengumuman';
      break;
    case pageType.blastsCreate:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/blasts/${blastId || currentBlast?._id}`,
          name: 'Pengumuman',
        },
        {
          pathname: '#',
          name: 'Buat Pengumuman',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/blasts/${blastId || currentBlast?._id}/create`;
      title = 'Buat Pengumuman';
      break;
    case pageType.posts:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/blasts/${currentBlast._id}`,
          name: 'Pengumuman',
        },
        {
          pathname: '#',
          name: currentBlastPost.title,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/posts/${currentBlastPost._id}`;
      title = currentBlastPost.title;
      break;
    case pageType.postsEdit:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/blasts/${currentBlast._id}`,
          name: 'Pengumuman',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/posts/${currentBlastPost._id}`,
          name: currentBlastPost?.title || 'Post',
        },
        {
          pathname: '#',
          name: `[UBAH] ${currentBlastPost.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/posts/${currentBlastPost._id}/edit`;
      title = `[UBAH] ${currentBlastPost.title}`;
      break;
    case pageType.postsComment:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/blasts/${currentBlast._id}`,
          name: 'Pengumuman',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/posts/${currentBlastPost._id}`,
          name: currentBlastPost?.title || 'Post',
        },
        {
          pathname: '#',
          name: `[KOMENTAR] ${currentBlastPost.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/posts/${currentBlastPost._id}/comments/${currentComment?._id}`;
      title = `[KOMENTAR] ${currentBlastPost.title}`;
      break;
    case pageType.checkIns:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: '#',
          name: 'Pertanyaan',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam?._id}/check-ins/${currentTeam?.checkIn?._id}`;
      title = 'Pertanyaan';
      break;
    case pageType.checkInsCreate:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/check-ins/${currentCheckIn._id}`,
          name: 'Pertanyaan',
        },
        {
          pathname: '#',
          name: 'Buat Pertanyaan',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/check-ins/${currentCheckIn._id}/create`;
      title = 'Buat Pertanyaan';
      break;
    case pageType.questions:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/check-ins/${currentCheckIn._id}`,
          name: 'Pertanyaan',
        },
        {
          pathname: '#',
          name: currentCheckInQuestion.title,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/questions/${currentCheckInQuestion._id}`;
      title = currentCheckInQuestion.title;
      break;
    case pageType.questionsEdit:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/check-ins/${currentCheckIn._id}`,
          name: 'Pertanyaan',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/questions/${currentCheckInQuestion._id}`,
          name: currentCheckInQuestion.title,
        },
        {
          pathname: '#',
          name: `[UBAH] ${currentCheckInQuestion.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/questions/${currentCheckInQuestion._id}/edit`;
      title = `[UBAH] ${currentCheckInQuestion.title}`;
      break;
    case pageType.questionsComment:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/check-ins/${currentCheckIn._id}`,
          name: 'Pertanyaan',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/questions/${currentCheckInQuestion._id}`,
          name: currentCheckInQuestion.title,
        },
        {
          pathname: '#',
          name: `[KOMENTAR] ${currentCheckInQuestion.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/questions/${currentCheckInQuestion?._id}/comments/${currentComment?._id}`;
      title = `[KOMENTAR] ${currentCheckInQuestion.title}`;
      break;
    case pageType.schedules:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: '#',
          name: 'Jadwal',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentTeam?.schedule?._id}`;
      title = 'Jadwal';
      break;
    case pageType.schedulesPublic:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule._id}`,
          name: 'Jadwal',
        },
        {
          pathname: '#',
          name: 'Sinkronisasi Jadwal',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule?._id}/public-calendar`;
      title = 'Sinkronisasi Jadwal';
      break;
    case pageType.schedulesCreate:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule._id}`,
          name: 'Jadwal',
        },
        {
          pathname: '#',
          name: 'Buat Jadwal',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule?._id}/create`;
      title = 'Buat Jadwal';
      break;
    case pageType.events:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule._id}`,
          name: 'Jadwal',
        },
        {
          pathname: '#',
          name: currentScheduleEvent.title,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/events/${currentScheduleEvent?._id}`;
      title = currentScheduleEvent.title;
      break;
    case pageType.eventsEdit:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule._id}`,
          name: 'Jadwal',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/events/${currentScheduleEvent._id}`,
          name: currentScheduleEvent.title,
        },
        {
          pathname: '#',
          name: `[UBAH] ${currentScheduleEvent.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/events/${currentScheduleEvent?._id}/edit`;
      title = `[UBAH] ${currentScheduleEvent.title}`;
      break;
    case pageType.eventsComment:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule._id}`,
          name: 'Jadwal',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/events/${currentScheduleEvent._id}`,
          name: currentScheduleEvent.title,
        },
        {
          pathname: '#',
          name: `[KOMENTAR] ${currentScheduleEvent.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/events/${currentScheduleEvent?._id}/comments/${currentComment?._id}`;
      title = `[KOMENTAR] ${currentScheduleEvent.title}`;
      break;
    case pageType.occurrences:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule._id}`,
          name: 'Jadwal',
        },
        {
          pathname: '#',
          name: currentOccurrence.title,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/occurrences/${currentOccurrence?._id}`;
      title = currentOccurrence.title;
      break;
    case pageType.occurrencesEdit:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule._id}`,
          name: 'Jadwal',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/events/${currentOccurrence.event}/occurrences/${currentOccurrence._id}`,
          name: currentOccurrence.title,
        },
        {
          pathname: '#',
          name: `[UBAH] ${currentOccurrence.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/occurrences/${currentOccurrence?._id}/edit`;
      title = `[UBAH] ${currentOccurrence.title}`;
      break;
    case pageType.occurrencesComment:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/schedules/${currentSchedule._id}`,
          name: 'Jadwal',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/events/${currentOccurrence.event}/occurrences/${currentOccurrence._id}`,
          name: currentOccurrence.title,
        },
        {
          pathname: '#',
          name: `[KOMENTAR] ${currentOccurrence.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/occurrences/${currentOccurrence?._id}/comments/${currentComment?._id}`;
      title = `[KOMENTAR] ${currentOccurrence.title}`;
      break;
    case pageType.boards:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: '#',
          name: 'Tugas - Kanban',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/boards/${boardId || currentBoard?._id}/kanban/all`;
      title = `Tugas - ${currentTeam.name} - Kanban`;
      break;
    case pageType.boardsList:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: '#',
          name: 'Tugas - List',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/boards/${boardId || currentBoard?._id}/list/all`;
      title = `Tugas - ${currentTeam.name} - List`;
      break;
    case pageType.boardsCalendar:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: '#',
          name: 'Tugas - Kalender',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/boards/${boardId || currentBoard?._id}/calendar/all`;
      title = `Tugas - ${currentTeam.name} - Kalender`;
      break;
    case pageType.cards:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/boards/${currentBoard._id}`,
          name: 'Tugas',
        },
        {
          pathname: '#',
          name: currentCard.name,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/cards/${currentCard?._id}`;
      title = currentCard?.name;
      break;
    case pageType.cardsComment:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/boards/${currentBoard._id}`,
          name: 'Tugas',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/cards/${currentCard._id}`,
          name: currentCard.name,
        },
        {
          pathname: '#',
          name: `[KOMENTAR] ${currentCard.name}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/cards/${currentCard?._id}/comments/${currentComment?._id}`;
      title = `[KOMENTAR] ${currentCard.name}`;
      break;
    case pageType.myTasksListCardsComment:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/my-tasks/list/all`,
          name: 'Semua Tugas - List',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/my-tasks/list/all/teams/${currentTeam._id}/cards/${currentCard._id}`,
          name: currentCard.name,
        },
        {
          pathname: '#',
          name: `[KOMENTAR] ${currentCard.name}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/my-tasks/list/all/teams/${currentTeam._id}/cards/${currentCard?._id}/comments/${currentComment?._id}`;
      title = `[KOMENTAR] ${currentCard.name}`;
      break;
    case pageType.myTasksCalendarCardsComment:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/my-tasks/calendar/all`,
          name: 'Semua Tugas - Kalender',
        },
        {
          pathname: `/companies/${companyId || currentCompany?._id}/my-tasks/calendar/all/teams/${currentTeam._id}/cards/${currentCard._id}`,
          name: currentCard.name,
        },
        {
          pathname: '#',
          name: `[KOMENTAR] ${currentCard.name}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/my-tasks/calendar/all/teams/${currentTeam._id}/cards/${currentCard?._id}/comments/${currentComment?._id}`;
      title = `[KOMENTAR] ${currentCard.name}`;
      break;
    case pageType.buckets:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        ...newBucketDocPath,
        {
          pathname: '#',
          name: currentBucket.title === 'Docs & Files' ? 'Dokumen & File' : currentBucket.title,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/buckets/${currentBucket?._id}`;
      if (currentBucket.title === 'Docs & Files') {
        title = 'Dokumen & File';
      } else {
        title = `Folder - ${currentBucket.title}`;
      }
      break;
    case pageType.bucketsEdit:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        ...newBucketDocPath,
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/buckets/${currentBucket._id}`,
          name: currentBucket.title,
        },
        {
          pathname: '#',
          name: `[UBAH] ${currentBucket.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/buckets/${currentBucket?._id}/edit`;
      title = `[UBAH] Folder ${currentBucket.title}`;
      break;
    case pageType.docs:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        ...newBucketDocPath,
        {
          pathname: '#',
          name: currentBucketDoc.title,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/docs/${currentBucketDoc?._id}`;
      title = currentBucketDoc.title;
      break;
    case pageType.docsCreate:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        ...newBucketDocPath,
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/buckets/${currentBucket._id}`,
          name: currentBucket.title,
        },
        {
          pathname: '#',
          name: 'Buat Dokumen',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/buckets/${currentBucket?._id}/create`;
      title = 'Buat Dokumen';
      break;
    case pageType.docsEdit:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        ...newBucketDocPath,
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/docs/${currentBucketDoc._id}`,
          name: currentBucketDoc.title || 'Dokumen',
        },
        {
          pathname: '#',
          name: `[UBAH] ${currentBucketDoc.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/docs/${currentBucketDoc?._id}/edit`;
      title = `[UBAH] ${currentBucketDoc.title}`;
      break;
    case pageType.docsComment:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        ...newBucketDocPath,
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/docs/${currentBucketDoc._id}`,
          name: currentBucketDoc.title || 'Dokumen',
        },
        {
          pathname: '#',
          name: `[KOMENTAR] ${currentBucketDoc.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/docs/${currentBucketDoc?._id}/comments/${currentComment?._id}`;
      title = `[KOMENTAR] ${currentBucketDoc.title}`;
      break;
    case pageType.files:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        ...newBucketDocPath,
        {
          pathname: '#',
          name: currentBucketFile.title,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/files/${currentBucketFile?._id}`;
      title = currentBucketFile.title;
      break;
    case pageType.filesEdit:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        ...newBucketDocPath,
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/files/${currentBucketFile._id}`,
          name: currentBucketFile.title,
        },
        {
          pathname: '#',
          name: `[UBAH] ${currentBucketFile.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/files/${currentBucketFile?._id}/edit`;
      title = `[UBAH] ${currentBucketFile.title}`;
      break;
    case pageType.filesComment:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'team',
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}`,
          name: currentTeam.name,
        },
        ...newBucketDocPath,
        {
          pathname: `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/files/${currentBucketFile._id}`,
          name: currentBucketFile.title,
        },
        {
          pathname: '#',
          name: `[KOMENTAR] ${currentBucketFile.title}`,
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/teams/${currentTeam._id}/files/${currentBucketFile?._id}/comments/${currentComment?._id}`;
      title = `[KOMENTAR] ${currentBucketFile.title}`;
      break;
    case pageType.myTasks:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          pathname: '#',
          name: 'My Tasks',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/my-tasks`;
      title = 'My Tasks';
      break;
    case pageType.billings:
      routes = [
        {
          label: 'home',
          pathname: `/companies/${companyId || currentCompany?._id}`,
          name: 'Beranda',
        },
        {
          label: 'company',
          pathname: '#',
          name: currentCompany?.name,
        },
        {
          pathname: '#',
          name: 'Tagihan & Pembayaran',
        },
      ];
      url = `/companies/${companyId || currentCompany?._id}/billings`;
      title = 'Tagihan & Pembayaran';
      break;
    default:
      break;
  }

  const routesAndTitle = {
    routes: [...routes],
    title,
    url,
  };

  return routesAndTitle;
}

export {
  generateRoutesAndTitle,
};
