import pdfImage from '../assets/Attachment/pdf.png';
import xlsImage from '../assets/Attachment/xls.png';
import zipImage from '../assets/Attachment/zip.png';
import rarImage from '../assets/Attachment/rar.png';
import docImage from '../assets/Attachment/doc.png';
import mp4Image from '../assets/Attachment/mp4.png';
import mp3Image from '../assets/Attachment/mp3.png';
import pptImage from '../assets/Attachment/ppt.png';
import txtImage from '../assets/Attachment/txt.png';
import csvImage from '../assets/Attachment/csv.png';
import xcfImage from '../assets/Attachment/xcf.png';
import svgImage from '../assets/Attachment/svg.png';
import psdImage from '../assets/Attachment/psd.png';
import aiImage from '../assets/Attachment/ai.png';
import threeGpImage from '../assets/Attachment/3gp.png';
import unknownImage from '../assets/Attachment/unknown.png';
import { getFileExt } from '../utilities/stringUtil';

const getMimeTypeUrl = ({ mimeType, fileUrl }) => {
  if (!mimeType || !fileUrl) return null;
  let imageUrl;
  let isImage = false;
  switch (mimeType) {
    case 'image/JPEG':
    case 'image/jpeg':
    case 'image/JPG':
    case 'image/jpg':
    case 'image/PNG':
    case 'image/png':
    case 'image/GIF':
    case 'image/gif':
      imageUrl = fileUrl;
      isImage = true;
      break;
    case 'application/pdf':
      imageUrl = pdfImage;
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
      imageUrl = xlsImage;
      break;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      imageUrl = pptImage;
      break;
    case 'application/zip':
      imageUrl = zipImage;
      break;
    case 'application/vnd.rar':
      imageUrl = rarImage;
      break;
    case 'video/mp4':
      imageUrl = mp4Image;
      break;
    case 'audio/mpeg':
    case 'audio/mp3':
      imageUrl = mp3Image;
      break;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      imageUrl = docImage;
      break;
    case 'text/plain':
      imageUrl = txtImage;
      break;
    case 'text/csv':
      imageUrl = csvImage;
      break;
    case 'image/svg+xml':
      imageUrl = svgImage;
      break;
    default:
      imageUrl = unknownImage;
  }

  if (imageUrl === unknownImage) {
    const fileExt = getFileExt(fileUrl);
    switch (fileExt) {
      case 'JPEG':
      case 'jpeg':
      case 'JPG':
      case 'jpg':
      case 'jfif':
      case 'ico':
      case 'PNG':
      case 'png':
      case 'GIF':
      case 'gif':
        imageUrl = fileUrl;
        isImage = true;
        break;
      case 'pdf':
        imageUrl = pdfImage;
        break;
      case 'xls':
      case 'xlsx':
        imageUrl = xlsImage;
        break;
      case 'ppt':
      case 'pptx':
        imageUrl = pptImage;
        break;
      case 'zip':
        imageUrl = zipImage;
        break;
      case 'rar':
        imageUrl = rarImage;
        break;
      case 'mp4':
        imageUrl = mp4Image;
        break;
      case '3gp':
        imageUrl = threeGpImage;
        break;
      case 'mpeg':
      case 'mp3':
        imageUrl = mp3Image;
        break;
      case 'docx':
      case 'doc':
        imageUrl = docImage;
        break;
      case 'txt':
        imageUrl = txtImage;
        break;
      case 'csv':
        imageUrl = csvImage;
        break;
      case 'svg':
        imageUrl = svgImage;
        break;
      case 'xcf':
        imageUrl = xcfImage;
        break;
      case 'psd':
        imageUrl = psdImage;
        break;
      case 'ai':
        imageUrl = aiImage;
        break;
      default:
        imageUrl = unknownImage;
    }
  }

  return {
    imageUrl,
    isImage,
  };
};

export {
  getMimeTypeUrl,
};
