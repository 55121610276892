/* eslint-disable vars-on-top */
/* eslint-disable no-var */
import React, { useState, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import CallMadeIcon from '@mui/icons-material/CallMade';
import PropTypes from 'prop-types';
import DateBox from '../../../components/UI/DateBox/DateBox';
import DeletePopUp from './DeletePopUp/DeletePopUp';
import EditPopUp from './EditPopUp/EditPopUp';
import styles from './ManageAttachments.module.css';
import AttachmentFile from './AttachmentFile/AttachmentFile';
import AttachFileContainer from '../../AttachFileContainer/AttachFileContainer';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv/ButtonDiv';
import { GlobalContext } from '../../../contexts/GlobalStateProvider';
import { checkIfUserAuthorizedToEditCardAttachment, checkIfUserAuthorizedToModifyCard } from '../../../actions/UserActions';

const ManageAttachments = ({ card, loading, handleLoading }) => {
  const [{ user, currentRoleUser }] = useContext(GlobalContext);
  const [showDeletePopUp, setShowDeletePopUp] = useState();
  const [showEditPopUp, setShowEditPopUp] = useState();

  const handleClick = (url) => {
    const fileUrl = url?.charAt(0) === '/'
      ? process.env.REACT_APP_PRIMARY_API_URL + url
      : url;
    window.open(fileUrl);
  };

  const handleShowDeletePopUp = (event, attachmentId) => {
    setShowDeletePopUp(attachmentId);
    setShowEditPopUp();
    // disable onclick on the parent getting trigerred. stop here.
    // eslint-disable-next-line no-redeclare
    if (!event) var { event } = window;
    // eslint-disable-next-line no-param-reassign
    event.cancelBubble = true;
    if (event.stopPropagation) event.stopPropagation();
  };

  const handleShowEditPopUp = (event, attachmentId) => {
    setShowEditPopUp(attachmentId);
    setShowDeletePopUp();
    // disable onclick on the parent getting trigerred. stop here.
    // eslint-disable-next-line no-redeclare
    if (!event) var { event } = window;
    // eslint-disable-next-line no-param-reassign
    event.cancelBubble = true;
    if (event.stopPropagation) event.stopPropagation();
  };

  return (
    <>
      {card.attachments !== undefined ? <p className={styles.mediumTitle}>Lampiran</p> : null}

      {card.attachments !== undefined && card.attachments.length === 0 && !loading
      && checkIfUserAuthorizedToModifyCard(card, user, currentRoleUser)
        ? (
          <AttachFileContainer handleLoading={handleLoading} type="card">
            <div className={styles.askDropContainer}>
              <p>Klik atau lepaskan file disini untuk mengunggah</p>
            </div>
          </AttachFileContainer>
        )
        : null}

      {card.attachments !== undefined && card.attachments.length === 0 && !loading
      && !checkIfUserAuthorizedToModifyCard(card, user, currentRoleUser)
        ? (
          <div className={styles.askDropContainer}>
            <p>Anggota tugas ini bisa mengunggah file lampiran disini</p>
          </div>
        )
        : null}

      {loading ? (
        <div className={styles.loadingContainer}>
          <Spinner animation="border" size="sm" variant="warning" />
        </div>
      ) : null}

      {card.attachments !== undefined && card.attachments.length > 0 ? (
        <>
          {card.attachments.map((attachment) => (
            <>
              <div className={styles.container}>
                <div onClick={() => handleClick(attachment.url)} className={styles.iconSection}>
                  <AttachmentFile attachment={attachment} />
                </div>
                <div className={styles.contentActionSection}>
                  <div onClick={() => handleClick(attachment.url)} className={styles.titleSection}>
                    <h1>{attachment.name}</h1>
                    <CallMadeIcon />
                  </div>
                  <div className={styles.actionSection}>
                    <DateBox date={attachment.createdAt} detail />
                    <div className={styles.wrapper}><span>-</span></div>
                    {checkIfUserAuthorizedToEditCardAttachment(attachment, card,
                      user, currentRoleUser) && (
                      <ButtonDiv
                        onClick={(event) => handleShowEditPopUp(event, attachment._id)}
                        className={styles.wrapper}
                      >
                        <p>Ubah</p>
                      </ButtonDiv>
                    )}
                    {showEditPopUp === attachment._id
                      ? (
                        <EditPopUp
                          attachment={attachment}
                          handleShowEditPopUp={handleShowEditPopUp}
                        />
                      )
                      : null}
                    <div className={styles.wrapper}><span>-</span></div>
                    {checkIfUserAuthorizedToEditCardAttachment(attachment, card, user,
                      currentRoleUser) && (
                      <ButtonDiv
                        onClick={(event) => handleShowDeletePopUp(event, attachment._id)}
                        className={styles.wrapper}
                      >
                        <p>Hapus</p>
                      </ButtonDiv>
                    )}
                    {showDeletePopUp === attachment._id
                      ? (
                        <DeletePopUp
                          attachment={attachment}
                          handleShowDeletePopUp={handleShowDeletePopUp}
                        />
                      ) : null}
                  </div>
                </div>
              </div>
            </>
          ))}
        </>
      ) : null}

    </>
  );
};

ManageAttachments.propTypes = {
  card: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  handleLoading: PropTypes.func.isRequired,
};

export default ManageAttachments;
