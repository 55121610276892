import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TextField, useMediaQuery } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import styles from './WithDueDateSection.module.css';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../UI_V2/Input/Button';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';

const WithDueDateSection = ({ dueDate, handleSetDueDate, handleHideDueDateSection }) => (
  <div className={styles.container}>
    <div className={styles.text}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
      >
        <DesktopDatePicker
          inputFormat="yyyy/MM/dd"
          value={dueDate}
          onChange={handleSetDueDate}
          renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
        />
      </LocalizationProvider>
    </div>
    <div onClick={handleHideDueDateSection} className={styles.button}>
      <RemoveIcon />
    </div>
  </div>
);

WithDueDateSection.propTypes = {
  dueDate: PropTypes.object.isRequired,
  handleSetDueDate: PropTypes.func.isRequired,
  handleHideDueDateSection: PropTypes.func.isRequired,
};

WithDueDateSection.defaultProps = {};

export default memo(WithDueDateSection);
