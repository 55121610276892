import { cloneDeep } from 'lodash';
import { actionTypes } from '../reducers/reducer';
import {
  ApiConstants, CardConstants, BoardConstants,
} from '../constants';
import apiUtil from '../utilities/apiUtil';
import {
  getLastListCreatedAt,
  getLastListIndex,
  mergeObjectListAndRemoveDuplicate,
  getIndexItemByIDFromList,
  updateListProperty,
  getItemByIDFromList,
} from '../utilities/arrayUtil';
import { BoardActions, TeamActions, CommentActions } from '.';
import { ErrorException } from '../utilities/handleError';

/*
  Dispatcher
*/

function dispatchCurrentBoardList({ currentBoardLists }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_BOARD_LISTS,
    currentBoardLists,
  });
}

function dispatchCurrentCard({ currentCard }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_CARD,
    currentCard,
  });
}

function dispatchCurrentCardList({ currentCardList }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_CARD_LIST,
    currentCardList,
  });
}

function dispatchCurrentCardComment({ currentCardComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_CARD_COMMENT,
    currentCardComment,
  });
}

function dispatchPreviousCardComment({ previousCardComment }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_CARD_COMMENT,
    previousCardComment,
  });
}

function dispatchUpdateCurrentCard({ updateCurrentCard }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_CARD,
    updateCurrentCard,
  });
}

function dispatchPreviousBoardList({ previousBoardList }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_BOARD_LIST,
    previousBoardList,
  });
}

function dispatchUpdateCurrentCardList({ updateCurrentCardList }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_CARD_LIST,
    updateCurrentCardList,
  });
}

/*
  SetterDispatcher
*/

function setCurrentBoardList({ currentBoardLists }, dispatch) {
  if (!currentBoardLists) return;

  dispatchCurrentBoardList(
    { currentBoardLists: cloneDeep(currentBoardLists) }, dispatch,
  );
}

function setCurrentCard({ currentCard }, dispatch) {
  if (!currentCard) return;

  dispatchCurrentCard(
    { currentCard: cloneDeep(currentCard) }, dispatch,
  );
}

function setCurrentCardComment({ currentCardComment }, dispatch) {
  if (!currentCardComment) return;

  dispatchCurrentCardComment(
    { currentCardComment: cloneDeep(currentCardComment) }, dispatch,
  );
}

function setCurrentCardList({ currentCardList }, dispatch) {
  if (!currentCardList) return;

  dispatchCurrentCardList(
    { currentCardList: cloneDeep(currentCardList) }, dispatch,
  );
}

function setPreviousCardComment({ previousCardComment }, dispatch) {
  if (!previousCardComment) return;

  dispatchPreviousCardComment(
    { previousCardComment: cloneDeep(previousCardComment) }, dispatch,
  );
}

function setPreviousBoardList({ previousBoardList }, dispatch) {
  if (!previousBoardList) return;

  dispatchPreviousBoardList(
    { previousBoardList: cloneDeep(previousBoardList) }, dispatch,
  );
}

/*
  Helpers
*/

function modifyResponseLoadComment({ result, previousResult }) {
  return {
    ...previousResult,
    comments: result?.data?.comments,
  };
}

function replaceItemListToTheNewest({ indexList, currentList, newlists }) {
  const lists = currentList;
  const oldList = currentList[indexList];
  const mergeOldWithNewList = {
    ...oldList,
    cards: newlists.cards,
  };
  lists.splice(indexList, 1, mergeOldWithNewList);

  return lists;
}

/*
  Method
*/

function incomingCardUpdate({ currentCard }, dispatch) {
  if (!currentCard) return;
  const updateCurrentCard = (card) => {
    const newCard = {
      ...currentCard,
      comments: card?.comments,
      cheers: card?.cheers,
    };

    return cloneDeep(newCard);
  };

  dispatchUpdateCurrentCard({ updateCurrentCard }, dispatch);
}

function incomingCardArchive({ currentCard }, dispatch) {
  if (!currentCard) return;

  const updateCurrentCard = (card) => {
    const newCard = {
      ...currentCard,
      archived: currentCard?.archived,
      updatedAt: currentCard?.updatedAt,
      comments: card?.comments,
      cheers: card?.cheers,
    };

    return cloneDeep(newCard);
  };

  dispatchUpdateCurrentCard({ updateCurrentCard }, dispatch);
}

function incomingCardListUpdate({ list }, dispatch) {
  if (!list) return;

  const updateCurrentCardList = (currentCardList) => {
    const newList = {
      ...list,
      cards: currentCardList?.cards,
    };

    return cloneDeep(newList);
  };

  dispatchUpdateCurrentCardList({ updateCurrentCardList }, dispatch);
}

function incomingCardComment({
  currentComment, typeAction, updateCommentCheer, keyProperty = 'comments',
}, dispatch) {
  if (!currentComment) return;

  const updateCurrentCard = (currentCard) => updateListProperty({
    keyProperty,
    newData: currentComment,
    currentList: currentCard,
    typeAction,
    actionEdit: updateCommentCheer,
  });

  dispatchUpdateCurrentCard({ updateCurrentCard }, dispatch);
}

async function initiateCard({ cardId }, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.CARD({ cardId }));

    setCurrentBoardList({ currentBoardLists: result?.data?.board?.lists }, dispatch);
    setPreviousBoardList({ previousBoardList: result?.data?.board?.lists }, dispatch);
    BoardActions.setCurrentBoard({ currentBoard: result?.data?.board }, dispatch);
    BoardActions.setCurrentBoardLabels({
      currentBoard: result?.data?.board?.labels,
    }, dispatch);
    TeamActions.saveCurrentTeam({ currentTeam: result?.data?.currentTeam }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const getCurrentCardList = async ({ cardId, companyId, teamId }) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.CARD_LIST({ cardId }), {
      params: {
        companyId,
        teamId,
      },
    });
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const getAndSaveCurrentCardList = async ({ cardId, companyId, teamId }, dispatch) => {
  try {
    const result = await getCurrentCardList({ cardId, companyId, teamId });
    setCurrentCardList({ currentCardList: result?.data?.list }, dispatch);
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function initiateCardV2({
  cardId, currentCard, currentTeam,
  currentBoardId, teamId, companyId,
  currentBoard, currentBoardLists,
}, dispatch) {
  try {
    let result = {
      data: {
        card: currentCard,
        boardId: currentCard?.board,
      },
    };
    if (currentCard?._id !== cardId) {
      result = await apiUtil.get(ApiConstants.URL_V2.CARD({ cardId }), {
        params: {
          companyId,
          teamId,
        },
      });
      setCurrentCard({ currentCard: result?.data?.card }, dispatch);
      await getAndSaveCurrentCardList({ cardId, companyId, teamId }, dispatch);
    }

    // refresh currentboard & team if changing card URL
    if (currentBoardId !== result?.data?.boardId) {
      await BoardActions.initiateBoardV2(
        {
          boardId: result?.data?.boardId,
          companyId,
          teamId,
          currentBoard,
          currentBoardLists,
        },
        dispatch,
      );
      await TeamActions.initiateTeam({
        teamId,
        companyId,
        currentTeam,
      }, dispatch);
    }

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreCards({
  list,
  handleUpdateList,
  companyId,
  teamId,
  isCardsLengthAtTheLimitOrAbove,
  triggerLoadMore,
  handleAddListCompleteFetchCards,
  endLoadings,
}) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.LIST_CARDS({ listId: list?._id }), {
      params: {
        limit: CardConstants.limitCard,
        lastIndex: getLastListIndex(list?.cards),
        companyId,
        teamId,
      },
    });
    const newList = mergeObjectListAndRemoveDuplicate({
      currentObjectList: list,
      nextObjectList: result?.data,
      keyObject: 'cards',
    });

    if (isCardsLengthAtTheLimitOrAbove(result?.data?.cards)) {
      handleUpdateList(newList);
      triggerLoadMore(Math.random());
    } else {
      handleAddListCompleteFetchCards(newList);
      triggerLoadMore();
      endLoadings();
    }
    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function initiateCardComment({
  cardId, companyId, teamId, limit,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.CARD_COMMENT({ cardId }), {
      params: {
        limit,
        companyId,
        teamId,
      },
    });

    setCurrentCardComment({ currentCardComment: result?.data?.comments }, dispatch);

    return CommentActions.sliceResultDataforLastCommentNearLimitComment(result);
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreCardComment({
  cardId, currentCard, companyId, teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V2.CARD_COMMENT({ cardId }), {
      params: {
        limit: CardConstants.limitComment,
        createdAt: getLastListCreatedAt(currentCard?.comments),
        companyId,
        teamId,
      },
    });

    const modifiedResult = modifyResponseLoadComment(
      { result, previousResult: currentCard },
    );
    const mergedCard = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentCard,
      nextObjectList: modifiedResult,
      keyObject: 'comments',
    });

    setPreviousCardComment({ previousCardComment: modifiedResult?.comments }, dispatch);
    setCurrentCard({ currentCard: mergedCard }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function moveCard({
  boardId,
  data,
  companyId,
  socket,
  currentMoveBoardLists,
  card,
  teamId,
}, dispatch) {
  try {
    const body = {
      data,
      selector: {
        boardId,
      },
    };
    const { destination, draggableId, source } = data;
    const result = await apiUtil.patch(ApiConstants.URL_V1.CARDS_POSITION(), body, {
      params: {
        companyId,
        teamId,
      },
    });
    const updateCurrentCardList = (currentCardList) => {
      if (!currentMoveBoardLists) return currentCardList;
      if (destination.droppableId !== currentCardList?._id) {
        const newList = getItemByIDFromList(currentMoveBoardLists, destination.droppableId);
        if (newList) {
          return cloneDeep(newList);
        }
      }
      return currentCardList;
    };
    dispatchUpdateCurrentCardList({ updateCurrentCardList }, dispatch);

    const updateCurrentBoardLists = (currentBoardLists) => {
      const newLists = BoardActions.reorderCardPositionWithUncompleteBoardLists(
        {
          destination,
          draggableId,
          source,
          currentBoardLists,
          card,
        },
      );
      return cloneDeep(newLists);
    };
    if (card) {
      BoardActions.dispatchUpdateCurrentBoardLists({ updateCurrentBoardLists }, dispatch);
    }

    if (socket) {
      socket.emit(BoardConstants.socketEvent.CARD_MOVE, body);
    }
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function updateCard({
  body, cardId, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.CARD({ cardId }), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function archiveCard({ cardId, companyId, teamId }) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.CARD_ARCHIVE({ cardId }), {}, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function archiveCards({ body, companyId, teamId }) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.CARDS_ARCHIVE(), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function unArchiveCard({
  body, cardId, companyId, teamId,
}) {
  try {
    const result = await apiUtil.patch(ApiConstants.URL_V1.CARD_UNARCHIVE({ cardId }), body, {
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function createCard({
  boardId, listId, companyId, teamId, cardName, isAccessPrivate, dueDate,
}) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CARDS(),
      {
        selector: { boardId, listId },
        data: { name: cardName, isPublic: !isAccessPrivate, dueDate },
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

export {
  setCurrentBoardList,
  setPreviousBoardList,
  setPreviousCardComment,
  setCurrentCard,
  initiateCard,
  initiateCardV2,
  loadMoreCards,
  initiateCardComment,
  loadMoreCardComment,
  incomingCardUpdate,
  incomingCardArchive,
  incomingCardListUpdate,
  incomingCardComment,
  dispatchUpdateCurrentCard,
  updateCard,
  archiveCard,
  archiveCards,
  unArchiveCard,
  moveCard,
  createCard,
};
