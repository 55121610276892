/* eslint-disable consistent-return */
import React, {
  useState, useContext, useEffect, useRef, useCallback, memo,
} from 'react';
import { useSnackbar } from 'notistack';
import {
  cloneDeep, find, isEmpty, isNumber,
} from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Modal from 'react-bootstrap/Modal';
import { ModalSwitch, ModalRoute } from 'react-router-modal-gallery';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import styles from './ListContainer.module.css';
import List from './List/List';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import CardDetailContainer from '../CardDetailContainer/CardDetailContainer';
import CreateCardForm from './CreateCardForm/CreateCardForm';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import { ShowUpdateNameListContext } from './ListContext';
import ChangeListNameForm from './ChangeListNameForm/ChangeListNameForm';
import CreateListButton from './CreateListButton/CreateListButton';
import RoundActionMenu from '../../components/UI/RoundActionMenu/RoundActionMenu';
import ListMenu from './ListMenu/ListMenu';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import OverlayButton from '../../components/UI/Button/OverlayButton/OverlayButton';
import ChangeCompleteIconMenu from './ChangeCompleteIconMenu/ChangeCompleteIconMenu';
import ManageLabelsContainer from '../ManageLabelsContainer/ManageLabelsContainer';
import ManageDueDateContainer from '../ManageDueDateContainer/ManageDueDateContainer';
import ManageMultipleMembersContainer from '../ManageMultipleMembersContainer/ManageMultipleMembersContainer';
import QuickCommentsWithBox from '../CardDetailContainer/QuickCommentsWithBox/QuickCommentsWithBox';
import ManageMoveCard from '../CardDetailContainer/ManageMoveCard/ManageMoveCard';
import ManageDescWithBox from '../CardDetailContainer/ManageDescWithBox/ManageDescWithBox';
import ChangeNameFormWithBox from '../CardDetailContainer/ChangeNameFormWithBox/ChangeNameFormWithBox';
import ButtonDiv from '../../components/UI/Button/ButtonDiv/ButtonDiv';
import ListCard from './ListCard/ListCard';
import { BoardActions, CardActions, ListActions } from '../../actions';
import { BoardConstants } from '../../constants';
import { hasPermission } from '../../actions/RoleActions';
import { roles } from '../../constants/RoleConstants';
import { FeedbackDialogGeneral } from '../../components/UI_V2/Feedback/Dialog';

function ListContainer({
  updatedBoardId, shouldFetch, onShouldFetch, socket, shouldFetchCards, onShouldFetchCards,
}) {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const [{
    user,
    currentBoard,
    currentBoardLists,
    editCardItems,
    filteredLists,
    currentCompany,
    currentTeam,
    currentRoleUser,
  }, dispatch] = useContext(GlobalContext);
  const [showAddCard, setShowAddCard] = useState('');
  const [showUpdateNameList, setShowUpdateNameList] = useState('');
  const [showBackground, setShowBackground] = useState(false);
  const [showListMenu, setShowListMenu] = useState('');
  const [showChangeCompleteIconMenu, setShowChangeCompleteIconMenu] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [boardUpdateListPos, setBoardUpdateListPos] = useState();
  const [cardUpdateListPos, setCardUpdateListPos] = useState();
  const [showEdit, setShowEdit] = useState('');
  const [showMove, setShowMove] = useState('');
  const [showLabel, setShowLabel] = useState('');
  const [showDueDate, setShowDueDate] = useState('');
  const [showMember, setShowMember] = useState('');
  const [showNote, setShowNote] = useState('');
  const [showName, setShowName] = useState('');
  const [showQuickComment, setShowQuickComment] = useState('');
  const [isErrorLoadMore, setStatusErrorLoadMore] = useState(false);
  const [localBoardId, setLocalBoardId] = useState(params.boardId);
  const [localLists, setLocalLists] = useState(currentBoardLists);
  const [listCompleteFetchCards, setListCompleteFetchCards] = useState([]);
  const [configListBoard, setConfigListBoard] = useState({});

  const { companyId, teamId } = params;
  const boardId = updatedBoardId;

  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const getDisplayedLists = useCallback(
    () => filteredLists.filter((item) => !item.notDisplayed),
    [filteredLists],
  );
  const listsDisplayed = getDisplayedLists();

  const isBoardListsLengthAtTheLimitOrAbove = useCallback(
    (lists) => isNumber(lists?.length) && lists?.length >= BoardConstants.limitList,
    [],
  );

  const handleAddListCompleteFetchCards = useCallback((id) => {
    setListCompleteFetchCards((prevValue) => [...prevValue, id]);
  }, []);

  const handleUpdateLocalLists = useCallback((lists) => {
    setLocalLists(lists);
  }, []);

  const handleUpdateLocalBoardId = useCallback((id) => {
    setLocalBoardId(id);
  }, []);

  const applyCurrentBoardListsAndResetTriggersFetchCards = useCallback((lists) => {
    BoardActions.incomingListUpdate({
      replaceCard: true,
      lists,
      typeAction: BoardConstants.typeCallback.EDIT,
    }, dispatch);
    setListCompleteFetchCards([]);
    onShouldFetchCards([]);
  }, []);

  const triggerFetchCards = useCallback((lists) => {
    const result = BoardActions.getWhichListsNeedToFetchCards({ currentBoardLists: lists });
    if (result?.length > 0) onShouldFetchCards(result);
  }, []);

  useEffect(() => {
    if (!shouldFetch) return;
    if (!currentBoard?._id || currentBoard?._id !== params.boardId) return;
    const fetchMoreLists = async () => {
      try {
        const isLocalBoardIdTheSame = localBoardId === params.boardId;
        if (!isLocalBoardIdTheSame) {
          handleUpdateLocalBoardId(params.boardId);
          handleUpdateLocalLists(currentBoardLists);
        }
        const result = await BoardActions.loadMoreBoardListsV2({
          currentBoard,
          companyId,
          teamId,
          localLists: isLocalBoardIdTheSame ? localLists : currentBoardLists,
          handleUpdateLocalLists,
          triggerFetchCards,
          isBoardListsLengthAtTheLimitOrAbove,
          onShouldFetch,
        }, dispatch);
      } catch (error) {
        const status = handleStatusMsg(error, 'error');
        if (status?.message) {
          enqueueSnackbar(status.message, {
            variant: 'error',
          });
        }
        setStatusErrorLoadMore(true);
      }
    };

    fetchMoreLists();
  }, [isErrorLoadMore, shouldFetch]);

  useEffect(() => {
    if (user._id === undefined) return;
    if (!currentBoard?._id || currentBoard?._id !== params.boardId) return;
    handleUpdateLocalLists(currentBoardLists);
    handleUpdateLocalBoardId(params.boardId);
  }, [currentBoardLists]);

  useEffect(() => {
    if (shouldFetch) return;
    if (!currentBoard?._id || currentBoard?._id !== params.boardId) return;
    if (listCompleteFetchCards?.length === 0 && shouldFetchCards?.length === 0) return;
    if (listCompleteFetchCards?.length !== shouldFetchCards?.length) return;
    applyCurrentBoardListsAndResetTriggersFetchCards(listCompleteFetchCards);
  }, [listCompleteFetchCards]);

  const revertBoardLists = (payload) => {
    const {
      prevCurrentBoardLists,
      prevFilteredBoardLists,
    } = payload;
    BoardActions.setCurrentBoardLists({ currentBoardLists: prevCurrentBoardLists }, dispatch);
    BoardActions.setFilteredBoardLists({ filteredLists: prevFilteredBoardLists }, dispatch);
  };

  useEffect(() => {
    if (boardUpdateListPos !== undefined) {
      const patchApiBoardUpdateListPos = async () => {
        // const boardId = params.boardId;
        const data = { ...boardUpdateListPos };
        delete data.prevCurrentBoardLists;
        delete data.prevFilteredBoardLists;

        try {
          const result = await ListActions.moveList({
            boardId, data, companyId, socket, teamId,
          });

          const status = handleStatusMsg(result, 'success');

          enqueueSnackbar(status.message, {
            variant: 'success',
          });
        } catch (err) {
          const status = handleStatusMsg(err, 'error');

          enqueueSnackbar(status.message, {
            variant: 'error',
          });
          const payloadRevert = {
            ...boardUpdateListPos,
          };
          revertBoardLists(payloadRevert);
        } finally {
          const endLoadings = handleLoadings(`moveList-${data.draggableId}`, [...loadings], 'end');
          setLoadings([...endLoadings]);
        }
      };
      patchApiBoardUpdateListPos();
    }
  }, [boardUpdateListPos]);

  useEffect(() => {
    if (cardUpdateListPos === undefined) {
      return;
    }

    const patchApiCardUpdateListPos = async () => {
      // const boardId = params.boardId;
      const data = { ...cardUpdateListPos };
      delete data.prevCurrentBoardLists;
      delete data.prevFilteredBoardLists;

      try {
        const result = await CardActions.moveCard({
          boardId, data, companyId, socket, teamId,
        }, dispatch);

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });

        const payloadRevert = {
          ...cardUpdateListPos,
        };

        revertBoardLists(payloadRevert);
      } finally {
        const endLoadings = handleLoadings(`moveCard-${data.draggableId}`, [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    patchApiCardUpdateListPos();
  }, [cardUpdateListPos]);

  const handleShowAddCard = (listId) => {
    setShowAddCard(listId);
  };

  const handleShowListMenu = (listId) => {
    setShowListMenu(listId);
  };

  const handleCancelShowListMenu = () => {
    setShowListMenu('');
  };

  const handleShowChangeCompleteIconMenu = (listId) => {
    setShowChangeCompleteIconMenu(listId);
  };

  const handleCancelShowChangeCompleteIconMenu = () => {
    setShowChangeCompleteIconMenu('');
  };

  const handleShowUpdateNameList = (listId, listName) => {
    setShowUpdateNameList(listId);
  };

  const onDragEnd = (result) => {
    setShowBackground(false);
    const {
      destination, source, draggableId, type,
    } = result;
    if (destination === null) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    // check apakah yang dipindahin list atau card
    // jika list...
    if (type === 'list') {
      const startLoadings = handleLoadings(`moveList-${draggableId}`, [...loadings], 'start');
      setLoadings([...startLoadings]);
      const updateCurrentBoardLists = (prevCurrentBoardLists) => {
        const newLists = BoardActions.reorderListPosition({
          destination, source, draggableId, currentBoardLists: prevCurrentBoardLists,
        });
        return cloneDeep(newLists);
      };
      BoardActions.dispatchUpdateCurrentBoardLists({ updateCurrentBoardLists }, dispatch);
      const updateFilteredBoardLists = (prevFilteredBoardLists) => {
        const newLists = BoardActions.reorderListPosition({
          destination, source, draggableId, currentBoardLists: prevFilteredBoardLists,
        });
        return cloneDeep(newLists);
      };
      BoardActions.dispatchUpdateFilteredBoardLists({ updateFilteredBoardLists }, dispatch);
      const payload = {
        ...result,
        prevCurrentBoardLists: currentBoardLists,
        prevFilteredBoardLists: filteredLists,
      };
      setBoardUpdateListPos(payload);
    } else {
      const startLoadings = handleLoadings(`moveCard-${draggableId}`, [...loadings], 'start');
      setLoadings([...startLoadings]);
      const updateCurrentBoardLists = (prevCurrentBoardLists) => {
        const newLists = BoardActions.reorderCardPosition({
          destination, source, draggableId, currentBoardLists: prevCurrentBoardLists,
        });
        return cloneDeep(newLists);
      };
      BoardActions.dispatchUpdateCurrentBoardLists({ updateCurrentBoardLists }, dispatch);
      const updateFilteredBoardLists = (prevFilteredBoardLists) => {
        const newLists = BoardActions.reorderCardPosition({
          destination, source, draggableId, currentBoardLists: prevFilteredBoardLists,
        });
        return cloneDeep(newLists);
      };
      BoardActions.dispatchUpdateFilteredBoardLists({ updateFilteredBoardLists }, dispatch);

      const payload = {
        ...result,
        prevCurrentBoardLists: currentBoardLists,
        prevFilteredBoardLists: filteredLists,
      };
      setCardUpdateListPos(payload);
    }
  };

  const onDragStart = (result) => {
    if (result.type === 'list') {
      setShowBackground(true);
    }
  };

  const onDragUpdate = (result) => {
  //
  };

  const onBeforeDragStart = (result) => {
    if (result.type === 'list') {
      setShowBackground(true);
    }
    setShowEdit('');
  };

  const handleCancelEditCardMenu = (event) => {
    const id = event?.target?.id;

    // this should be here because need to make sure editcard menu closed before dragging card.
    if (id === 'editCardMenu'
      || id === 'editCardMenuIcon'
      || event?.target?.tagName === 'path') {
      //
    } else if (showEdit !== '') {
      setShowEdit('');
    }
  };

  const handleCancelShowLabel = () => {
    setShowLabel('');
  };

  const handleCancelShowMove = () => {
    setShowMove('');
  };

  const handleCancelShowDueDate = () => {
    setShowDueDate('');
  };

  const handleCancelShowMember = () => {
    setShowMember('');
  };

  const handleCancelShowNote = () => {
    setShowNote('');
  };

  const handleCancelShowName = () => {
    setShowName('');
  };

  const handleCancelShowQuickComment = () => {
    setShowQuickComment('');
  };

  const handleCancelAllCardMenu = () => {
    handleCancelShowMove();
    handleCancelShowLabel();
    handleCancelShowDueDate();
    handleCancelShowMember();
    handleCancelShowNote();
    handleCancelShowName();
    handleCancelShowQuickComment();
  };

  const handleShowEditCardMenu = (cardId) => {
    handleCancelAllCardMenu();
    setShowEdit((prev) => {
      if (prev === cardId) {
        return '';
      }
      return cardId;
    });
  };

  const handleShowMove = (payload) => {
    handleCancelAllCardMenu();
    setShowMove((prev) => {
      if (prev?.length < 1) {
        return payload;
      }
      return '';
    });
  };

  const handleShowLabel = (payload) => {
    handleCancelAllCardMenu();
    setShowLabel((prev) => {
      if (prev?.length < 1) {
        return payload;
      }
      return '';
    });
  };

  const handleShowDueDate = (payload) => {
    handleCancelAllCardMenu();
    setShowDueDate((prev) => {
      if (prev?.length < 1) {
        return payload;
      }
      return '';
    });
  };

  const handleShowMember = (payload) => {
    handleCancelAllCardMenu();
    setShowMember((prev) => {
      if (prev?.length < 1) {
        return payload;
      }
      return '';
    });
  };

  const handleShowNote = (payload) => {
    handleCancelAllCardMenu();
    setShowNote((prev) => {
      if (prev?.length < 1) {
        return payload;
      }
      return '';
    });
  };

  const handleShowName = (payload) => {
    handleCancelAllCardMenu();
    setShowName((prev) => {
      if (prev?.length < 1) {
        return payload;
      }
      return '';
    });
  };

  const handleShowQuickComment = (payload) => {
    handleCancelAllCardMenu();
    setShowQuickComment((prev) => {
      if (prev?.length < 1) {
        return payload;
      }
      return '';
    });
  };

  const handleUpdateConfigList = (incomingNewConfig) => {
    setConfigListBoard((prev) => ({
      ...prev,
      ...incomingNewConfig,
    }));
  };

  return (
    <>
      <DragDropContext
        onBeforeDragStart={onBeforeDragStart}
        onDragUpdate={onDragUpdate}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <Droppable key="all-lists" droppableId="all-lists" direction="horizontal" type="list">
          {(providedDroppable) => (
            <div
              className={styles.ListContainer__outerList}
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              {listsDisplayed.map((list, index) => (
                <>
                  <Draggable key={list._id} draggableId={list._id} index={index}>
                    {(provided) => (
                      <>
                        <List
                          innerRef={provided.innerRef}
                          provided={provided}
                        >
                          <OverlayButton wait={`moveList-${list._id}`} anchorId={boardUpdateListPos ? `moveList-${boardUpdateListPos?.draggableId}` : `moveList-${list._id}`} loadings={loadings}>
                            <div className={`${styles.ListContainer__noMargin} ${styles.headerSection}`}>
                              <div className={styles.headerSection__title}>
                                {list?.complete?.status
                                  ? (
                                    <>
                                      <ButtonDiv
                                        onClick={() => handleShowChangeCompleteIconMenu(list._id)}
                                          // eslint-disable-next-line max-len
                                        className={styles.headerSection__title__completeIcon__container}
                                      >
                                        {list?.complete?.type === 'done' ? (
                                          <Tooltip title="List Selesai. Untuk meletakkan tugas-tugas yang udah selesai. Semua ga akan dapet pengingat tenggat waktu lagi dari tugas-tugas di List ini.">
                                            <div
                                                // eslint-disable-next-line max-len
                                              className={styles.headerSection__title__completeIcon}
                                            >
                                              <CheckCircleIcon />
                                            </div>
                                          </Tooltip>
                                            ) : (
                                              <Tooltip title="List Blok. Untuk meletakkan tugas-tugas yang batal dikerjakan. Semua ga akan dapet pengingat tenggat waktu lagi dari tugas-tugas di List ini.">
                                                <div className={`${styles.headerSection__title__completeIcon} ${styles.headerSection__title__blockedIcon}`}>
                                                  <CancelIcon />
                                                </div>
                                              </Tooltip>
                                            )}

                                      </ButtonDiv>
                                      {showChangeCompleteIconMenu === list._id
                                        && (
                                        <ChangeCompleteIconMenu
                                          cancel={handleCancelShowChangeCompleteIconMenu}
                                          list={list}
                                        />
                                        )}
                                    </>
                                    )
                                  : null}
                                <div className={styles.headerSection__title__text}>
                                  <ShowUpdateNameListContext.Provider
                                    value={[showUpdateNameList, setShowUpdateNameList]}
                                  >
                                    {showUpdateNameList === list._id
                                      ? <ChangeListNameForm list={list} />
                                      : (
                                        <h1
                                          onClick={isUserManagerOrAbove
                                            ? () => handleShowUpdateNameList(list._id)
                                            : () => null}
                                        >
                                          {list.name}
                                        </h1>
                                      )}
                                  </ShowUpdateNameListContext.Provider>
                                </div>
                              </div>
                              <div className={styles.headerSection__more}>
                                <RoundActionMenu
                                  clicked={handleShowListMenu}
                                  id={list._id}
                                  style={
                                    !isEmpty(configListBoard?.[list?._id])
                                      ? {
                                        backgroundColor: '#28a745',
                                      }
                                      : {}
                                  }
                                >
                                  <MoreHorizIcon
                                    style={
                                      !isEmpty(configListBoard?.[list?._id])
                                        ? {
                                          color: '#fff',
                                        }
                                        : {}
                                    }
                                  />
                                </RoundActionMenu>
                                {showListMenu === list._id
                                    && (
                                    <ListMenu
                                      allConfigListBoard={configListBoard}
                                      setConfigListBoard={handleUpdateConfigList}
                                      cancel={handleCancelShowListMenu}
                                      list={list}
                                    />
                                    )}
                                {showMove === list._id
                                    && (
                                    <ManageMoveCard
                                      cancel={handleCancelShowMove}
                                      left
                                      card={editCardItems.card}
                                      list={editCardItems.list}
                                      socket={socket}
                                    />
                                    )}
                                {showLabel === list._id
                                    && (
                                    <ManageLabelsContainer
                                      left
                                      cancel={handleCancelShowLabel}
                                      card={editCardItems.card}
                                    />
                                    )}
                                {showDueDate === list._id
                                    && (
                                    <ManageDueDateContainer
                                      position="topLeft"
                                      cancel={handleCancelShowDueDate}
                                      card={editCardItems.card}
                                    />
                                    )}
                                {showMember === list._id
                                    && (
                                    <ManageMultipleMembersContainer
                                      cancel={handleCancelShowMember}
                                      type="card"
                                      left
                                      card={editCardItems.card}
                                      list={editCardItems.list}
                                    />
                                    )}
                                {showNote === list._id
                                    && (
                                    <ManageDescWithBox
                                      cancel={handleCancelShowNote}
                                      left
                                      card={editCardItems.card}
                                      list={editCardItems.list}
                                    />
                                    )}
                                {showName === list._id
                                    && (
                                    <ChangeNameFormWithBox
                                      cancel={handleCancelShowName}
                                      left
                                      card={editCardItems.card}
                                      list={editCardItems.list}
                                    />
                                    )}
                                {showQuickComment === list._id
                                    && (
                                    <QuickCommentsWithBox
                                      cancel={handleCancelShowQuickComment}
                                      type="card"
                                      left
                                      card={editCardItems.card}
                                      list={editCardItems.list}
                                    />
                                    )}
                              </div>
                            </div>
                            <SeparatorLine dark />
                            <div className={styles.ListContainer__minHeight}>
                              <ListCard
                                shouldFetch={shouldFetchCards}
                                handleAddListCompleteFetchCards={handleAddListCompleteFetchCards}
                                allConfigListBoard={configListBoard}
                                setConfigListBoard={handleUpdateConfigList}
                                indexListCard={index}
                                list={list}
                                cardUpdateListPos={cardUpdateListPos}
                                loadings={loadings}
                                showEdit={showEdit}
                                handleShowEditCardMenu={handleShowEditCardMenu}
                                handleCancelEditCardMenu={handleCancelEditCardMenu}
                                handleCancelAllCardMenu={handleCancelAllCardMenu}
                                handleShowMove={handleShowMove}
                                handleShowLabel={handleShowLabel}
                                handleShowDueDate={handleShowDueDate}
                                handleShowMember={handleShowMember}
                                handleShowNote={handleShowNote}
                                handleShowName={handleShowName}
                                handleShowQuickComment={handleShowQuickComment}
                              />
                            </div>
                            {showAddCard === list._id
                              ? (
                                <CreateCardForm
                                  list={list}
                                  handleShowAddCard={handleShowAddCard}
                                />
                              )
                              : (
                                <ButtonDiv
                                  onClick={() => handleShowAddCard(list._id)}
                                  className={styles.ListContainer__buttonBottom}
                                >
                                  <AddIcon />
                                  <a>Buat Tugas</a>
                                </ButtonDiv>
                              )}
                          </OverlayButton>
                        </List>
                      </>
                    )}
                  </Draggable>
                </>
              ))}
              {isUserManagerOrAbove && (
              <div style={showBackground ? { marginLeft: '283px' } : { marginLeft: '5px' }} className={styles.createListSection}>
                <CreateListButton />
              </div>
              )}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ModalSwitch renderModal={({ open, redirectToBack }) => (
        <FeedbackDialogGeneral
          open={open}
          onClose={
          () => history.push(`/companies/${companyId}/teams/${teamId}/boards/${currentBoard?._id}/kanban/all`)
          }
          maxWidth="md"
        >
          <ModalRoute
            defaultParentPath={`/companies/${companyId}/teams/${teamId}/boards/${currentBoard?._id}/kanban/all`}
            path="/companies/:companyId/teams/:teamId/boards/:boardId/kanban/all/cards/:cardId"
            component={CardDetailContainer}
          />
        </FeedbackDialogGeneral>
      )}
      >
        <ModalRoute
          defaultParentPath={`/companies/${companyId}/teams/${teamId}/boards/${currentBoard?._id}/kanban/all`}
          path="/companies/:companyId/teams/:teamId/boards/:boardId/kanban/all/cards/:cardId"
          component={CardDetailContainer}
        />
      </ModalSwitch>
    </>
  );
}

ListContainer.propTypes = {
  updatedBoardId: PropTypes.string.isRequired,
  shouldFetch: PropTypes.number.isRequired,
  onShouldFetch: PropTypes.func.isRequired,
  shouldFetchCards: PropTypes.number.isRequired,
  onShouldFetchCards: PropTypes.func.isRequired,
  socket: PropTypes.func.isRequired,
};

export default memo(ListContainer);
