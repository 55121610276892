import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TimePicker from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import BoxPopOver from '../../components/UI/BoxPopOver/BoxPopOver';
import styles from './ManageDueDateContainer.module.css';
import Button from '../../components/UI/Button/Button';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import { CardActions } from '../../actions';
import { NavigationMenuBasic } from '../../components/UI_V2/Navigation/Menu';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';

const ManageDueDateContainer = ({
  cancel, card, position,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [isDatePickerOpen, handleSetDatePickerOpen,
    handleUnsetDatePickerOpen] = useDelayShowHideHandler();

  let initialDate;
  if (card.dueDate !== undefined) {
    initialDate = new Date(card.dueDate);
  } else {
    initialDate = new Date();
  }

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [postSelectedDate, setPostSelectedDate] = useState();
  const [deleteDate, setDeleteDate] = useState(false);

  const params = useParams();
  const { companyId } = params;

  useEffect(() => {
    if (postSelectedDate === undefined && deleteDate === false) {
      return;
    }

    const postApiDueDate = async () => {
      const cardId = params.cardId || card._id;
      const teamId = params.teamId || card.team?._id || card.team;
      let data;
      let waitKey;
      if (deleteDate === true) {
        data = { $unset: { dueDate: card.dueDate }, 'isNotified.dueOneDay': false, 'isNotified.dueOneHour': false };
        waitKey = 'deleteDueDateCard';
      } else {
        const isoDate = postSelectedDate.toISOString();
        data = { dueDate: isoDate, 'isNotified.dueOneDay': false, 'isNotified.dueOneHour': false };
        waitKey = 'changeDueDateCard';
      }

      try {
        const startLoadings = handleLoadings(waitKey, [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await CardActions.updateCard({
          teamId, cardId, body: data, companyId,
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings(waitKey, [...loadings], 'end');
        setLoadings([...endLoadings]);
        // close this container after finished
      }
    };
    postApiDueDate();
  }, [postSelectedDate, deleteDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // console.log("initial local format : " + date);
    // let isoDate = date.toISOString()
    // console.log("ISO UTC format : " + isoDate);
    // let convertIsoDate = new Date(isoDate);
    // console.log("converted local format from ISO : " + convertIsoDate);
  };

  const handlePostSelectedDate = () => {
    setPostSelectedDate(selectedDate);
  };

  const handleDeletedDate = () => {
    setDeleteDate(true);
  };

  return (
    // <BoxPopOver customStyle={fixedMode ?
    // { position: 'fixed', top: topPos, left: leftPos } : null} left={!!left}>
    <NavigationMenuBasic disabled={isDatePickerOpen} size="mdWider" onClose={cancel} position={position}>
      <div className={styles.container}>
        <div className={styles.ManageDueDateContainer__Header}>
          <h1 className="mr-auto">
            Ubah Tenggat Waktu
          </h1>
          {/* <CloseIcon onClick={cancel} /> */}
        </div>
        <SeparatorLine />
        <div className={styles.body}>
          <div className={styles.datePicker}>
            <p className={styles.ManageDueDateContainer__smallTitle}>Tanggal</p>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
            >
              <DesktopDatePicker
                onOpen={handleSetDatePickerOpen}
                onClose={handleUnsetDatePickerOpen}
                inputFormat="yyyy/MM/dd"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
              />
            </LocalizationProvider>
          </div>
          <div className={styles.timePicker}>
            <p className={styles.ManageDueDateContainer__smallTitle}>Waktu</p>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
            >
              <TimePicker
                onOpen={handleSetDatePickerOpen}
                onClose={handleUnsetDatePickerOpen}
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className={styles.footer}>
          <Button handleClick={handlePostSelectedDate} variant="success" size="sm" wait="changeDueDateCard" loadings={loadings}>Simpan</Button>
          <Button handleClick={handleDeletedDate} variant="danger" size="sm" wait="deleteDueDateCard" loadings={loadings}>Hapus</Button>
        </div>
      </div>
    </NavigationMenuBasic>
  );
};

ManageDueDateContainer.propTypes = {
  card: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'centerRight', 'centerLeft']),
};

ManageDueDateContainer.defaultProps = {
  position: 'topRight',
};

export default ManageDueDateContainer;
