import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './FileSnapshot.module.css';
import PrivateIcon from '../../../../components/UI/PrivateIcon/PrivateIcon';
import { AttachmentActions } from '../../../../actions';

const FileSnapshot = ({ file }) => {
  const [title, setTitle] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isImage, setIsImage] = useState();

  useEffect(() => {
    if (file.title === undefined) return;

    let tempTitle;
    if (file.title.length < 40) {
      tempTitle = file.title;
    } else {
      tempTitle = `${file.title.slice(0, 40)}...`;
    }

    setTitle(tempTitle);

    const fileUrl = file.url?.charAt(0) === '/'
      ? process.env.REACT_APP_PRIMARY_API_URL + file.url
      : file.url;
    const result = AttachmentActions.getMimeTypeUrl(
      { mimeType: file.mimeType, fileUrl },
    );

    setImageUrl(result?.imageUrl);
    setIsImage(result?.isImage);
  }, [file]);

  return (
    <div className={styles.main}>
      <div className={styles.main__icon}>
        <img src={imageUrl} />
      </div>
      <div className={styles.main__title}>
        <h1>
          <PrivateIcon data={file} size="small" />
          {' '}
          {title}
        </h1>
      </div>
    </div>
  );
};

FileSnapshot.propTypes = {
  file: PropTypes.object.isRequired,
};

export default FileSnapshot;
