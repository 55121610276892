import {
  isArray, uniqBy, concat, findIndex, cloneDeep, slice, find, trim, isEqual,
  sortBy, orderBy,
} from 'lodash';

function extractNamesFromObjectItems(arrayIds, items) {
  if (!isArray(arrayIds) || !isArray(items)) return null;
  const output = arrayIds
    .map((id) => items.find((item) => item._id === id))
    .filter((item) => item !== undefined)
    .map((item) => item.name || item.title)
    .join(', ');

  return output;
}

function getLastListCreatedAt(list) {
  if (!isArray(list)) return null;

  const lastList = list[list.length - 1];

  return lastList?.createdAt;
}

function getLastListDate(list, keyObjectProperty = 'createdAt', keyObjDate) {
  if (!isArray(list)) return null;

  const lastList = list[list.length - 1];

  return lastList?.[keyObjectProperty] || lastList?.[keyObjDate]?.[keyObjectProperty];
}

function isListEqual(firstList, secondList) {
  if (!isArray(firstList) || !isArray(secondList)) return false;

  const equal = isEqual(firstList, secondList);

  return equal;
}

const isListLengthEqual = (firstList, secondList) => {
  if (!isArray(firstList) || !isArray(secondList)) return false;
  return firstList.length === secondList.length;
};

function getLastListIndex(list) {
  if (!isArray(list)) return null;
  if (list.length === 0) return 0;
  return list.length - 1;
}

const filterListObjectByListObject = (originalList, comparisonList, keyObjectProperty = '_id') => {
  if (!isArray(originalList) || !isArray(comparisonList)) return [];
  return originalList.filter(
    (origElement) => !comparisonList.some(
      (compElement) => compElement[keyObjectProperty] === origElement[keyObjectProperty],
    ),
  );
};

const isAllElementExistInList = (comparisonList, originalList, keyObjectProperty = '_id') => {
  if (!isArray(comparisonList) || !isArray(originalList)) return false;
  const exist = originalList.filter(
    (origElement) => comparisonList.some(
      (compElement) => compElement[keyObjectProperty] === origElement[keyObjectProperty],
    ),
  ).length === comparisonList.length;
  return exist;
};

const extractIDFromObjectList = (list) => {
  if (!isArray(list)) return [];
  return list.map((item) => item._id);
};

function removeDuplicateByID(list) {
  if (!isArray(list)) return [];

  return uniqBy(list, '_id');
}

function removeDuplicatesByUserId(arr) {
  const userIdSet = new Set();
  const uniqueArray = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const item of arr) {
    if (!item.user || !item.user._id || !userIdSet.has(item.user._id)) {
      if (item.user && item.user._id) {
        userIdSet.add(item.user._id);
      }
      uniqueArray.push(item);
    }
  }

  return uniqueArray;
}

function mergeList({ currentList, nextList }) {
  if (!currentList || !nextList) return [];

  return concat(currentList, nextList);
}

function mergeNewList({ currentList, nextList }) {
  if (!currentList || !nextList) return [];

  return concat(nextList, currentList);
}

function getIndexItemByIDFromList(list, item, keyProperty, keyPropertyItem) {
  if (!list || !item) {
    return -1;
  }
  let selector = '_id';
  if (keyProperty) selector = keyProperty;
  let selectorItem = '_id';
  if (keyPropertyItem) selectorItem = keyPropertyItem;
  return findIndex(list, [selector, item[selectorItem]]);
}

function getItemByIDFromList(list, id) {
  if (!list || !id) {
    return -1;
  }
  return find(list, { _id: id });
}

const modifyItemPropertyByIDAndMoveToDestListIndex = (
  list, id, destIndex, keyProperty, newPropertyValue,
) => {
  if (!list || !id || !keyProperty || !newPropertyValue) {
    return -1;
  }
  const clonedList = cloneDeep(list);
  let item = getItemByIDFromList(clonedList, id);
  item = {
    ...item,
    [keyProperty]: newPropertyValue,
  };
  const sourceIndex = getIndexItemByIDFromList(clonedList, item);

  // hapus elemen array dari index awalnya
  clonedList.splice(sourceIndex, 1);
  // masukkan ulang elemen array ke index tujuan
  clonedList.splice(destIndex, 0, item);

  return clonedList;
};

function sortingListByCreatedAt(list) {
  if (!isArray(list)) return null;

  let sortedList = list;
  sortedList = sortedList.sort((a, b) => {
    const c = new Date(a.createdAt);
    const d = new Date(b.createdAt);
    return d - c;
  });

  return sortedList;
}

const convertEmailMultilineStringIntoArray = (value) => {
  if (!value) return [];
  const emails = value.split(/\n/);
  // eslint-disable-next-line no-useless-escape
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

  const removedWhitespaceEmails = emails.map((email) => trim(email));

  const validEmails = removedWhitespaceEmails.filter((email) => regex.test(email));

  return validEmails;
};

function sortingListByUpdatedAt(list) {
  if (!isArray(list)) return null;

  let sortedList = list;
  sortedList = sortedList.sort((a, b) => {
    const c = new Date(a.updatedAt);
    const d = new Date(b.updatedAt);
    return d - c;
  });

  return sortedList;
}

function sortingListByDate(list, keyDate) {
  if (!isArray(list)) return null;

  let sortedList = list;
  sortedList = sortedList.sort((a, b) => {
    const c = new Date(a?.[keyDate]);
    const d = new Date(b?.[keyDate]);
    return d - c;
  });

  return sortedList;
}

const insertItemArrayImmutable = (lists, index, newItem) => {
  const lengthList = lists?.length;
  const firstLists = slice(lists, 0, index) || [];
  const secondList = slice(lists, index, lengthList) || [];
  const concatList = concat(firstLists, newItem, secondList);

  return concatList;
};

const removeItemArrayImmutable = (lists, index, lengthRemove = 1) => {
  const lengthList = lists.length;
  const secondIndexToSlice = index + lengthRemove;
  const firstLists = slice(lists, 0, index) || [];
  const secondList = slice(lists, secondIndexToSlice, lengthList) || [];
  const concatList = concat(firstLists, secondList);

  return concatList;
};

const mergeListAndRemoveDuplicate = (currentList, nextList) => {
  if (!isArray(currentList) || !isArray(nextList)) return [];
  let newList = mergeList({ currentList, nextList });
  newList = removeDuplicateByID(newList);
  return newList;
};

function mergeObjectListAndRemoveDuplicate({ currentObjectList, nextObjectList, keyObject }) {
  if (!currentObjectList || !nextObjectList) return [];

  let newObjectList = mergeList(
    { currentList: currentObjectList?.[keyObject], nextList: nextObjectList?.[keyObject] },
  );
  newObjectList = removeDuplicateByID(newObjectList);
  const mergedObjectList = {
    ...currentObjectList,
    [keyObject]: newObjectList,
  };

  return mergedObjectList;
}

function mergeNewObjectListAndRemoveDuplicate({ currentObjectList, nextObjectList, keyObject }) {
  if (!currentObjectList || !nextObjectList) return [];

  let newObjectList = mergeNewList(
    { currentList: currentObjectList?.[keyObject], nextList: nextObjectList?.[keyObject] },
  );
  newObjectList = removeDuplicateByID(newObjectList);
  const mergedObjectList = {
    ...currentObjectList,
    [keyObject]: newObjectList,
  };

  return mergedObjectList;
}

const updateListProperty = ({
  keyProperty, newData, currentList, typeAction, actionEdit, reverse = true,
  actionAdd,
}) => {
  const clonedList = cloneDeep(currentList);
  let listData = clonedList?.[keyProperty];
  const indexNewData = getIndexItemByIDFromList(listData, newData);

  switch (typeAction) {
    case 'new':
      if (indexNewData >= 0) {
        listData[indexNewData] = newData;
      } else if (actionAdd) {
        listData = actionAdd(listData);
      } else {
        listData = insertItemArrayImmutable(
          listData,
          (reverse ? 0 : clonedList?.[keyProperty]?.length),
          newData,
        );
        // listData.splice((reverse ? 0 : clonedList?.[keyProperty]?.length), 0, newData);
      }
      break;
    case 'delete':
      if (indexNewData >= 0) {
        // listData.splice(indexNewData, 1);
        listData = removeItemArrayImmutable(
          listData,
          indexNewData,
        );
      }
      break;
    default:
      if (indexNewData >= 0) {
        if (actionEdit) {
          listData[indexNewData] = actionEdit(listData[indexNewData]);
        } else {
          listData[indexNewData] = cloneDeep(newData);
        }
      }
  }

  const finalList = { ...clonedList, [keyProperty]: listData };
  const result = cloneDeep(finalList);

  return result;
};

const updateListSocket = ({
  newData, destIndex, currentList, typeAction, reverse = true,
  actionAdd, actionEdit,
}) => {
  let listData = currentList;
  const indexNewData = getIndexItemByIDFromList(listData, newData);
  switch (typeAction) {
    case 'new':
      if (indexNewData >= 0) {
        if (destIndex) {
          listData[destIndex] = newData;
        } else {
          listData[indexNewData] = newData;
        }
      } else if (actionAdd) {
        listData = actionAdd(listData);
      } else {
        let index = reverse ? 0 : currentList?.length;
        if (destIndex) index = destIndex;
        listData = insertItemArrayImmutable(
          listData,
          index,
          newData,
        );
        // listData.splice((reverse ? 0 : currentList?.[keyProperty]?.length), 0, newData);
      }
      break;
    case 'delete':
      if (indexNewData >= 0) {
        // listData.splice(indexNewData, 1);
        listData = removeItemArrayImmutable(
          listData,
          indexNewData,
        );
      }
      break;
    default:
      if (indexNewData >= 0) {
        if (actionEdit) {
          listData[indexNewData] = actionEdit(
            listData[indexNewData],
            newData,
          );
        } else {
          listData[indexNewData] = newData;
        }
      }
  }

  listData = cloneDeep(listData);

  return listData;
};

const updateMultipleListSocket = ({
  newLists, currentList, typeAction, reverse = true,
  actionAdd, actionEdit,
}) => {
  let listData = currentList;

  newLists.forEach((listItem) => {
    const indexNewData = getIndexItemByIDFromList(listData, listItem);
    switch (typeAction) {
      case 'new':
        if (indexNewData >= 0) {
          listData[indexNewData] = listItem;
        } else if (actionAdd) {
          listData = actionAdd(listData);
        } else {
          listData = insertItemArrayImmutable(
            listData,
            (reverse ? 0 : currentList?.length),
            listItem,
          );
          // listData.splice((reverse ? 0 : currentList?.[keyProperty]?.length), 0, listItem);
        }
        break;
      case 'delete':
        if (indexNewData >= 0) {
          // listData.splice(indexNewData, 1);
          listData = removeItemArrayImmutable(
            listData,
            indexNewData,
          );
        }
        break;
      default:
        if (indexNewData >= 0) {
          if (actionEdit) {
            listData[indexNewData] = actionEdit(
              listData[indexNewData],
              listItem,
            );
          } else {
            listData[indexNewData] = listItem;
          }
        }
    }
  });

  listData = cloneDeep(listData);

  return listData;
};

/*
  Sorting function
*/

const sortListByStringValue = (list, keyObject) => {
  const items = list;
  items.sort((a, b) => {
    const itemA = a[keyObject]?.toUpperCase();
    const itemB = b[keyObject]?.toUpperCase();
    if (itemA < itemB) {
      return -1;
    }
    if (itemA > itemB) {
      return 1;
    }

    // item equal
    return 0;
  });
  return items;
};

const reverseSortListByStringValue = (list, keyObject) => {
  const items = list;
  items.sort((a, b) => {
    const itemA = a[keyObject]?.toUpperCase();
    const itemB = b[keyObject]?.toUpperCase();
    if (itemA < itemB) {
      return 1;
    }
    if (itemA > itemB) {
      return -1;
    }

    // item equal
    return 0;
  });
  return items;
};

const sortListByDateValue = (list, keyObject) => {
  const items = list;
  items.sort((a, b) => {
    const itemA = new Date(a[keyObject])?.getTime();
    const itemB = new Date(b[keyObject])?.getTime();
    if (itemA < itemB) {
      return -1;
    }
    if (itemA > itemB) {
      return 1;
    }

    // item equal
    return 0;
  });
  return items;
};

const reverseSortListByDateValue = (list, keyObject) => {
  const items = list;
  items.sort((a, b) => {
    const itemA = new Date(a[keyObject])?.getTime();
    const itemB = new Date(b[keyObject])?.getTime();
    if (itemA < itemB) {
      return 1;
    }
    if (itemA > itemB) {
      return -1;
    }

    // item equal
    return 0;
  });
  return items;
};

function cutArray(arr, size, start = 0) {
  return arr.slice(start, start + size);
}

/*
  New sorting function
*/

function sortByAlphabet(array, keyObjProperty = 'name') {
  return sortBy(array, [(o) => o[keyObjProperty].toLowerCase()]);
}

function sortByAlphabetReverse(array, keyObjProperty = 'name') {
  return sortBy(array, [(o) => o[keyObjProperty].toLowerCase()]).reverse();
}

function sortByDateNewestToOldest(array, keyObjProperty = 'createdAt') {
  return orderBy(array, [(item) => new Date(item[keyObjProperty])], ['desc']);
}

function sortByDateOldestToNewest(array, keyObjProperty = 'createdAt') {
  return orderBy(array, [(item) => new Date(item[keyObjProperty])], ['asc']);
}

export {
  getLastListCreatedAt,
  removeDuplicateByID,
  getItemByIDFromList,
  mergeList,
  mergeNewList,
  getIndexItemByIDFromList,
  sortingListByCreatedAt,
  mergeObjectListAndRemoveDuplicate,
  modifyItemPropertyByIDAndMoveToDestListIndex,
  updateListProperty,
  getLastListIndex,
  sortingListByDate,
  insertItemArrayImmutable,
  removeItemArrayImmutable,
  sortingListByUpdatedAt,
  mergeNewObjectListAndRemoveDuplicate,
  sortListByStringValue,
  reverseSortListByStringValue,
  sortListByDateValue,
  reverseSortListByDateValue,
  updateListSocket,
  updateMultipleListSocket,
  convertEmailMultilineStringIntoArray,
  isListEqual,
  filterListObjectByListObject,
  isAllElementExistInList,
  isListLengthEqual,
  mergeListAndRemoveDuplicate,
  getLastListDate,
  extractIDFromObjectList,
  extractNamesFromObjectItems,
  removeDuplicatesByUserId,
  cutArray,
  sortByAlphabet,
  sortByAlphabetReverse,
  sortByDateNewestToOldest,
  sortByDateOldestToNewest,
};
