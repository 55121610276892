import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './ListCardSnapshot.module.css';
import Color from '../../../../../themes/colors';
import SmallCard from '../../../../ListContainer/SmallCard/SmallCard';
import handleLoadings from '../../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import { ReportConstants } from '../../../../../constants';
import { useInfinityScrollHooks } from '../../../../../hooks/InfinityScrollHooks';
import { FeedbackLoadingMain } from '../../../../../components/UI_V2/Feedback/Loading';
import InfinityScroll from '../../../../../components/UI/InfinityScroll/InfinityScroll';
import { handleCompareDate, isDateBeforeNow } from '../../../../../utilities/dateUtil';
import DateSeparator from '../../../../CommentsSectionContainer/DateSeparator/DateSeparator';
import { initiateReportListCardSnapshot, loadMoreReportListCardSnapshot } from '../../../../../actions/ReportRecapActions';
import ListItemSkeleton from '../../../DataRealtimeReport/ItemUserReport/ListItemSkeleton/ListItemSkeleton';

const ListCardSnapshot = ({
  week,
  year,
  itemType,
}) => {
  /* Initiate list data */
  const initialStatePreviousReportListCardSnapshot = {
    data: new Array(ReportConstants.limitReport),
  };
  const initialStateCurrentReportListCardSnapshot = {
    data: [],
  };
  const [previousReportListCardSnapshot,
    setPreviousReportListCardSnapshot] = useState(initialStatePreviousReportListCardSnapshot);
  const [currentReportListCardSnapshot,
    setCurrentReportListCardSnapshot] = useState(initialStateCurrentReportListCardSnapshot);
  const [loadings, setLoadings] = useState([]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId, teamId, memberId,
  } = params;

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    const result = await loadMoreReportListCardSnapshot({
      memberId,
      teamId,
      companyId,
      week,
      year,
      itemType,
      setCurrentReportListCardSnapshot,
      setPreviousReportListCardSnapshot,
      currentReportListCardSnapshot,
    });

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousReportListCardSnapshot(initialStatePreviousReportListCardSnapshot);
    setCurrentReportListCardSnapshot(initialStateCurrentReportListCardSnapshot);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentReportListCardSnapshot,
    previousLists: previousReportListCardSnapshot?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: ReportConstants.limitReport,
    dontSorting: true,
  });

  const initiateReportListCardSnapshotApi = async () => {
    try {
      const startLoadings = handleLoadings('initiateReportListCardSnapshot', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await initiateReportListCardSnapshot({
        teamId,
        memberId,
        companyId,
        week,
        year,
        itemType,
        setCurrentReportListCardSnapshot,
        setPreviousReportListCardSnapshot,
        initialStatePreviousReportListCardSnapshot,
      });

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateReportListCardSnapshot', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (!week || !year || !itemType) return;
    initiateReportListCardSnapshotApi();
  }, [location, week, year, itemType]);

  const isItemTypeComplete = itemType === ReportConstants.typeRef.completed;
  const isItemTypeOngoing = itemType === ReportConstants.typeRef.ongoing;
  const isItemTypeOverdue = itemType === ReportConstants.typeRef.overdue;

  const today = new Date();

  return (
    <div className={styles.container}>
      <FeedbackLoadingMain
        loadingComponent={<ListItemSkeleton />}
        wait="initiateReportListCardSnapshot"
        loadings={loadings}
      >
        <InfinityScroll
          scrollableTarget="listReportListCardSnapshot"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="Belum ada tugas di kategori ini..."
        >
          {lists.map((item, index) => {
            let isSameDate = handleCompareDate(lists, item, (index - 1), 'createdAt');
            let dateSeparator = item.createdAt;
            let isCardOngoingButOverdue = false;
            if (isItemTypeComplete) {
              isSameDate = handleCompareDate(lists, item, (index - 1), 'setAt', 'complete');
              dateSeparator = item.complete.setAt;
            }

            if (isItemTypeOngoing || isItemTypeOverdue) {
              isSameDate = handleCompareDate(lists, item, (index - 1), 'dueDate');
              dateSeparator = item.dueDate;
            }

            if (isItemTypeOngoing) {
              isCardOngoingButOverdue = isDateBeforeNow(item.dueDate, today);
            }

            if (!isSameDate) {
              return (
                <>
                  <DateSeparator date={dateSeparator} />
                  <div className={styles.card}>
                    <SmallCard
                      name={item.name}
                      desc={item.desc}
                      labels={item.labels}
                      members={item.members}
                      dueDate={item.dueDate}
                      comments={item.comments}
                      attachments={item.attachments}
                      archived={item.archived.status}
                      complete={item.complete}
                      isCardPublic={item?.isPublic}
                      cardId={item?._id}
                      teamId={item.team}
                      isCardBlur={item?.isForbidden}
                      isCardOngoingButOverdue={isCardOngoingButOverdue}
                    />
                  </div>
                </>
              );
            }
            return (
              <div className={styles.card}>
                <SmallCard
                  name={item.name}
                  desc={item.desc}
                  labels={item.labels}
                  members={item.members}
                  dueDate={item.dueDate}
                  comments={item.comments}
                  attachments={item.attachments}
                  archived={item.archived.status}
                  complete={item.complete}
                  isCardPublic={item?.isPublic}
                  cardId={item?._id}
                  teamId={item.team}
                  isCardBlur={item?.isForbidden}
                  isCardOngoingButOverdue={isCardOngoingButOverdue}
                />
              </div>
            );
          })}
        </InfinityScroll>
      </FeedbackLoadingMain>
    </div>
  );
};

ListCardSnapshot.propTypes = {
  week: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
};

ListCardSnapshot.defaultProps = {};

export default memo(ListCardSnapshot);
